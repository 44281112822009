import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { value as value_45 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { createObj, int32ToString } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ofArray, toArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import react_select from "react-select";
import { reactApi as reactApi_1 } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import creatable from "react-select/creatable";

export function defaultStyling(state) {
    return {
        "&": {
            borderColor: "",
        },
        "&:hover": {
            borderColor: "",
        },
        borderColor: state.isFocused ? "#14BC9C" : "#B6BECB",
        boxShadow: "none",
    };
}

function NoOptionsMessage(props) {
    return createElement("div", {
        className: "mr-auto p-2",
        children: useTranslation()[0](props.inputValue),
    });
}

export function ToNoOptionsMessage(translationKey) {
    return FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_45("/Users/softwarewerkstatt/builds/UBMbxo2Re/1/softwarewerkstatt/werkzeugkoffer/werkzeugkoffer-web/src/Client/Components/Select.fs") + "#L") + int32ToString(value_45(29))) + ";") + "", value_45("ToNoOptionsMessage"), (props) => createElement("div", {
        className: "mr-auto p-2",
        children: useTranslation()[0](translationKey),
    }), undefined, undefined, "Of");
}

export function Select(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["className", join(" ", [])], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = ofArray([["options", toArray(props.Options)], ["placeholder", patternInput[0](props.PlaceholderKey)], ["onChange", props.OnChange], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["value", props.Value], ["isClearable", props.IsClearable], ["isDisabled", props.ComponentState === "disabled"], ["styles", {
                control: (baseStyles, state) => (Object.assign({}, baseStyles, ((props.ValidationMessage == null) ? defaultStyling(state) : {
                    "&": {
                        borderColor: "#EB4D4D",
                    },
                    "&:hover": {
                        borderColor: "#EB4D4D",
                    },
                }))),
                menuPortal: (baseStyles_1, state_1) => (Object.assign({}, baseStyles_1, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(react_select, createObj(props_2)))), delay(() => {
                const matchValue_2 = props.ValidationMessage;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_2;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message,
                    }));
                }
            }));
        }));
    })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function MultiSelect(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = ofArray([["options", toArray(props.Options)], ["placeholder", patternInput[0](props.PlaceholderKey)], ["onChange", props.OnChange], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["value", toArray(props.Value)], ["isClearable", props.IsClearable], ["isMulti", true], ["isDisabled", props.ComponentState === "disabled"], ["styles", {
                control: (baseStyles, state) => (Object.assign({}, baseStyles, ((props.ValidationMessage == null) ? defaultStyling(state) : {
                    "&": {
                        borderColor: "#EB4D4D",
                    },
                    "&:hover": {
                        borderColor: "#EB4D4D",
                    },
                }))),
                menuPortal: (baseStyles_1, state_1) => (Object.assign({}, baseStyles_1, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(react_select, createObj(props_2)))), delay(() => {
                const matchValue_2 = props.ValidationMessage;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_2;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message,
                    }));
                }
            }));
        }));
    })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function GroupedSelect(props) {
    let elems_2, elems_1, elems;
    const id = newGuid();
    const t = useTranslation()[0];
    const matchValue = props.Label;
    if (matchValue == null) {
        return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = toList(delay(() => {
            let props_8;
            return append(singleton((props_8 = ofArray([["key", `grouped-select-key-${props.Key}`], ["value", props.Value], ["options", toArray(props.Options)], ["placeholder", t(props.PlaceholderKey)], ["onChange", props.OnChange], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["formatGroupLabel", (groupOption_1) => createElement("span", {
                children: [groupOption_1.label],
            })], ["isClearable", props.IsClearable], ["isDisabled", props.ComponentState === "disabled"], ["styles", {
                control: (baseStyles_2, state_2) => (Object.assign({}, baseStyles_2, defaultStyling(state_2))),
                menuPortal: (baseStyles_3, state_3) => (Object.assign({}, baseStyles_3, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(react_select, createObj(props_8)))), delay(() => {
                const matchValue_2 = props.ValidationMessage;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const message_1 = matchValue_2;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message_1,
                    }));
                }
            }));
        })), ["children", reactApi_1.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        const label = matchValue;
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1.5"])], (elems_1 = [createElement("label", {
            htmlFor: id,
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }), createElement("div", createObj(ofArray([["data-test-id", `${label}-test-id`], (elems = toList(delay(() => {
            let props_3;
            return append(singleton((props_3 = ofArray([["key", `grouped-select-key-${props.Key}`], ["value", props.Value], ["options", toArray(props.Options)], ["placeholder", t(props.PlaceholderKey)], ["onChange", props.OnChange], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["formatGroupLabel", (groupOption) => createElement("span", {
                children: [groupOption.label],
            })], ["isClearable", props.IsClearable], ["isDisabled", props.ComponentState === "disabled"], ["styles", {
                control: (baseStyles, state) => (Object.assign({}, baseStyles, defaultStyling(state))),
                menuPortal: (baseStyles_1, state_1) => (Object.assign({}, baseStyles_1, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(react_select, createObj(props_3)))), delay(() => {
                const matchValue_1 = props.ValidationMessage;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_1;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message,
                    }));
                }
            }));
        })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])))], ["children", reactApi_1.Children.toArray(Array.from(elems_1))])])));
    }
}

export function GroupedSelectMulti(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["data-test-id", props.TestId], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_3;
            return append(singleton((props_3 = ofArray([["isMulti", true], ["options", toArray(props.Options)], ["placeholder", patternInput[0](props.PlaceholderKey)], ["onChange", props.OnChange], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["value", toArray(props.Value)], ["formatGroupLabel", (groupOption) => createElement("span", {
                children: [groupOption.label],
            })], ["isClearable", props.IsClearable], ["isMulti", true], ["isDisabled", props.ComponentState === "disabled"], ["styles", {
                control: (baseStyles, state) => (Object.assign({}, baseStyles, ((props.ValidationMessage == null) ? defaultStyling(state) : {
                    "&": {
                        borderColor: "#EB4D4D",
                    },
                    "&:hover": {
                        borderColor: "#EB4D4D",
                    },
                }))),
                menuPortal: (baseStyles_1, state_1) => (Object.assign({}, baseStyles_1, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(react_select, createObj(props_3)))), delay(() => {
                const matchValue_2 = props.ValidationMessage;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_2;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message,
                    }));
                }
            }));
        }));
    })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

export function MultiCreatable(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = toList(delay(() => {
        let matchValue, label;
        return append((matchValue = props.Label, (matchValue == null) ? (empty()) : ((label = matchValue, singleton(createElement("label", {
            className: join(" ", ["text-sm", "font-bold"]),
            children: label,
        }))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = ofArray([["options", toArray(props.Options)], ["placeholder", patternInput[0](props.PlaceholderKey)], ["onChange", props.OnChange], ["onCreateOption", props.OnCreateOption], ["noOptionsMessage", ToNoOptionsMessage(props.NoOptionsMessage)], ["value", toArray(props.Value)], ["isClearable", props.IsClearable], ["isMulti", true], ["formatCreateLabel", (value_11) => {
                const arg_1 = {
                    value: value_11,
                };
                return patternInput[1].t("select.create_value", arg_1);
            }], ["styles", {
                control: (baseStyles, state) => (Object.assign({}, baseStyles, defaultStyling(state))),
                menuPortal: (baseStyles_1, state_1) => (Object.assign({}, baseStyles_1, {
                    zIndex: 9999,
                })),
            }], ["menuPortalTarget", document.body], ["menuPosition", "fixed"]]), reactApi.createElement(creatable, createObj(props_2)))), delay(() => {
                const matchValue_1 = props.ValidationMessage;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    const message = matchValue_1;
                    return singleton(createElement("div", {
                        className: join(" ", ["mt-2", "text-red", "text-sm"]),
                        children: message,
                    }));
                }
            }));
        }));
    })), ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

