import { Union } from "./fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";

export class AppVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MWK", "I24", "EAGLETRACKMATE"];
    }
}

export function AppVariant_$reflection() {
    return union_type("Variant.AppVariant", [], AppVariant, () => [[], [], []]);
}

export const Variant = new AppVariant(1, []);

export const debugActive = false;

