import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { empty, toArray, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { ColumnDefinition$1, Table, CellContent } from "./Table.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { EntityType } from "../../Shared/Changelog.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

function referencedEntityCellValue(entity, value) {
    let matchResult, referencedEntity_4, referencedEntity_5, referencedEntity_6, referencedEntity_7;
    if (entity == null) {
        matchResult = 4;
    }
    else if (equals(entity.EntityType, new EntityType(4, []))) {
        matchResult = 0;
        referencedEntity_4 = entity;
    }
    else if (equals(entity.EntityType, new EntityType(0, []))) {
        matchResult = 1;
        referencedEntity_5 = entity;
    }
    else if (equals(entity.EntityType, new EntityType(1, []))) {
        matchResult = 2;
        referencedEntity_6 = entity;
    }
    else if (equals(entity.EntityType, new EntityType(2, []))) {
        matchResult = 3;
        referencedEntity_7 = entity;
    }
    else {
        matchResult = 4;
    }
    switch (matchResult) {
        case 0:
            return new CellContent(2, [referencedEntity_4.EntityName, () => {
                RouterModule_nav(ofArray(["receivers", referencedEntity_4.EntityId]), 1, 1);
            }]);
        case 1:
            return new CellContent(2, [referencedEntity_5.EntityName, () => {
                RouterModule_nav(ofArray(["tools", referencedEntity_5.EntityId]), 1, 1);
            }]);
        case 2:
            return new CellContent(2, [referencedEntity_6.EntityName, () => {
                RouterModule_nav(ofArray(["toolswithquantity", referencedEntity_6.EntityId]), 1, 1);
            }]);
        case 3:
            return new CellContent(2, [referencedEntity_7.EntityName, () => {
                RouterModule_nav(ofArray(["consumables", referencedEntity_7.EntityId]), 1, 1);
            }]);
        default:
            return new CellContent(0, [value]);
    }
}

export function ChangelogTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    return createElement(Table, (TableRows = toArray(props.Changelogs), {
        ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (dto) => (new CellContent(6, [dto.Timestamp])), "dateRange", "dateTime"), new ColumnDefinition$1("user", "", t("changelog.changed_by"), (dto_1) => (new CellContent(0, [dto_1.UpdatedByUser.Name])), "text", "text"), new ColumnDefinition$1("changed_property", "", t("changelog.changed_property"), (dto_2) => (new CellContent(0, [t(dto_2.ChangedProperty)])), "text", "text"), new ColumnDefinition$1("old_value", "", t("changelog.old_value"), (dto_3) => referencedEntityCellValue(dto_3.OldValueReferencedEntity, t(dto_3.OldValue)), "text", "text"), new ColumnDefinition$1("new_value", "", t("changelog.new_value"), (dto_4) => referencedEntityCellValue(dto_4.NewValueReferencedEntity, t(dto_4.NewValue)), "text", "text"), new ColumnDefinition$1("referenced_entity", "", t("changelog.referenced_entity"), (dto_5) => referencedEntityCellValue(dto_5.ReferencedEntity, ""), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["timestamp", "desc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }));
}

