import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, class_type, option_type, record_type, union_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ChangelogId_$reflection } from "./Shared.js";

export class ChangedProperty extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EntityCreated", "DynamicProperty", "InventoryNumber", "StorageSpace", "QrCodeIds", "Unit", "DisplayName", "Description", "AdditionalName", "ImageUrl", "SerialNumber", "Manufacturer", "Comment", "PrinzingCheckNumber", "Model", "LastInspectionDate", "CommercialDataComment", "WarrantyDate", "PurchasePrice", "SupplierArticleNumber", "Supplier", "PurchaseDate", "Name", "StartDate", "EndDate", "State", "StocktakingScannerRequired", "StocktakingToolsCanBeTaken", "StocktakingHelperAdded", "StocktakingHelperRemoved", "StocktakingResponsiblePersonAdded", "StocktakingResponsiblePersonRemoved", "StocktakingListHelperAdded", "StocktakingListHelperRemoved", "StocktakingListResultAdded", "StocktakingListResultRemoved", "StocktakingListCorrectionAdded"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? this$.fields[0] : ((this$.tag === 2) ? "inventory_number" : ((this$.tag === 3) ? "storage_space" : ((this$.tag === 4) ? "qr_code_ids" : ((this$.tag === 5) ? "unit" : ((this$.tag === 6) ? "display_name" : ((this$.tag === 7) ? "description" : ((this$.tag === 8) ? "additional_name" : ((this$.tag === 9) ? "image_url" : ((this$.tag === 10) ? "serial_number" : ((this$.tag === 11) ? "manufacturer" : ((this$.tag === 12) ? "comment" : ((this$.tag === 13) ? "prinzing_check_number" : ((this$.tag === 14) ? "model" : ((this$.tag === 15) ? "last_inspection_date" : ((this$.tag === 16) ? "commercial_data_comment" : ((this$.tag === 17) ? "warranty_date" : ((this$.tag === 18) ? "purchase_price" : ((this$.tag === 19) ? "supplier_article_number" : ((this$.tag === 20) ? "supplier" : ((this$.tag === 21) ? "purchase_date" : ((this$.tag === 22) ? "name" : ((this$.tag === 23) ? "start_date" : ((this$.tag === 24) ? "end_date" : ((this$.tag === 25) ? "state" : ((this$.tag === 26) ? "stocktaking_scanner_required" : ((this$.tag === 27) ? "stocktaking_tools_can_be_taken" : ((this$.tag === 28) ? "stocktaking_helper_added" : ((this$.tag === 29) ? "stocktaking_helper_removed" : ((this$.tag === 30) ? "stocktaking_responsible_person_added" : ((this$.tag === 31) ? "stocktaking_responsible_person_removed" : ((this$.tag === 32) ? "stocktaking_list_helper_added" : ((this$.tag === 33) ? "stocktaking_list_helper_removed" : ((this$.tag === 34) ? "stocktaking_list_result_added" : ((this$.tag === 35) ? "stocktaking_list_result_removed" : ((this$.tag === 36) ? "stocktaking_list_correction_added" : "event_created")))))))))))))))))))))))))))))))))));
    }
}

export function ChangedProperty_$reflection() {
    return union_type("Shared.Changelog.ChangedProperty", [], ChangedProperty, () => [[], [["Item", string_type]], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function ChangedProperty__ToI18nString(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0];
        case 2:
            return "general.inventory_number";
        case 3:
            return "tool.storage_space";
        case 4:
            return "general.qr_codes";
        case 5:
            return "general.unit";
        case 6:
            return "general.name";
        case 7:
            return "general.description";
        case 8:
            return "consumable.additional_name";
        case 9:
            return "general.image";
        case 10:
            return "general.serial_number";
        case 11:
            return "tool.manufacturer";
        case 12:
            return "general.comment";
        case 13:
            return "tool.prinzing.check_number";
        case 14:
            return "tool.model";
        case 15:
            return "tool.last_check";
        case 16:
            return "general.comment";
        case 17:
            return "general.gurantee_date";
        case 18:
            return "general.purchase_price";
        case 19:
            return "general.supplier_article_number";
        case 20:
            return "general.supplier";
        case 21:
            return "general.purchase_date";
        case 22:
            return "general.name";
        case 23:
            return "general.start_date";
        case 24:
            return "general.end_date";
        case 25:
            return "changelog.state";
        case 26:
            return "changelog.stocktaking_scanner_required";
        case 27:
            return "changelog.stocktaking_tools_can_be_taken";
        case 28:
            return "changelog.stocktaking_helper_added";
        case 29:
            return "changelog.stocktaking_helper_removed";
        case 30:
            return "changelog.stocktaking_responsible_person_added";
        case 31:
            return "changelog.stocktaking_responsible_person_removed";
        case 32:
            return "changelog.stocktaking_list_helper_added";
        case 33:
            return "changelog.stocktaking_list_helper_removed";
        case 34:
            return "changelog.stocktaking_list_result_added";
        case 35:
            return "changelog.stocktaking_list_result_removed";
        case 36:
            return "changelog.stocktaking_list_correction_added";
        default:
            return "event.created";
    }
}

export function ChangedProperty_Parse_Z721C83C5(value) {
    switch (value) {
        case "event_created":
            return new ChangedProperty(0, []);
        case "inventory_number":
            return new ChangedProperty(2, []);
        case "storage_space":
            return new ChangedProperty(3, []);
        case "qr_code_ids":
            return new ChangedProperty(4, []);
        case "unit":
            return new ChangedProperty(5, []);
        case "display_name":
            return new ChangedProperty(6, []);
        case "description":
            return new ChangedProperty(7, []);
        case "additional_name":
            return new ChangedProperty(8, []);
        case "image_url":
            return new ChangedProperty(9, []);
        case "serial_number":
            return new ChangedProperty(10, []);
        case "manufacturer":
            return new ChangedProperty(11, []);
        case "comment":
            return new ChangedProperty(12, []);
        case "prinzing_check_number":
            return new ChangedProperty(13, []);
        case "model":
            return new ChangedProperty(14, []);
        case "last_inspection_date":
            return new ChangedProperty(15, []);
        case "commercial_data_comment":
            return new ChangedProperty(16, []);
        case "warranty_date":
            return new ChangedProperty(17, []);
        case "purchase_price":
            return new ChangedProperty(18, []);
        case "supplier_article_number":
            return new ChangedProperty(19, []);
        case "supplier":
            return new ChangedProperty(20, []);
        case "purchase_date":
            return new ChangedProperty(21, []);
        case "name":
            return new ChangedProperty(22, []);
        case "start_date":
            return new ChangedProperty(23, []);
        case "end_date":
            return new ChangedProperty(24, []);
        case "state":
            return new ChangedProperty(25, []);
        case "stocktaking_scanner_required":
            return new ChangedProperty(26, []);
        case "stocktaking_tools_can_be_taken":
            return new ChangedProperty(27, []);
        case "stocktaking_helper_added":
            return new ChangedProperty(28, []);
        case "stocktaking_helper_removed":
            return new ChangedProperty(29, []);
        case "stocktaking_responsible_person_added":
            return new ChangedProperty(30, []);
        case "stocktaking_responsible_person_removed":
            return new ChangedProperty(31, []);
        case "stocktaking_list_helper_added":
            return new ChangedProperty(32, []);
        case "stocktaking_list_helper_removed":
            return new ChangedProperty(33, []);
        case "stocktaking_list_result_added":
            return new ChangedProperty(34, []);
        case "stocktaking_list_result_removed":
            return new ChangedProperty(35, []);
        case "stocktaking_list_correction_added":
            return new ChangedProperty(36, []);
        case "":
            throw new Error("Invalid empty changed property name");
        default:
            return new ChangedProperty(1, [value]);
    }
}

export class EntityType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Consumable", "Set", "Receiver", "Storage", "Location", "Category", "Stocktaking", "StocktakingList"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "tool_with_quantity" : ((this$.tag === 2) ? "consumable" : ((this$.tag === 3) ? "set" : ((this$.tag === 4) ? "receiver" : ((this$.tag === 5) ? "storage" : ((this$.tag === 6) ? "location" : ((this$.tag === 7) ? "category" : ((this$.tag === 8) ? "stocktaking" : ((this$.tag === 9) ? "stocktaking_list" : "tool"))))))));
    }
}

export function EntityType_$reflection() {
    return union_type("Shared.Changelog.EntityType", [], EntityType, () => [[], [], [], [], [], [], [], [], [], []]);
}

export function EntityType_Parse_Z721C83C5(value) {
    switch (value) {
        case "tool":
            return new EntityType(0, []);
        case "tool_with_quantity":
            return new EntityType(1, []);
        case "consumable":
            return new EntityType(2, []);
        case "set":
            return new EntityType(3, []);
        case "receiver":
            return new EntityType(4, []);
        case "storage":
            return new EntityType(5, []);
        case "location":
            return new EntityType(6, []);
        case "category":
            return new EntityType(7, []);
        case "stocktaking":
            return new EntityType(8, []);
        case "stocktaking_list":
            return new EntityType(9, []);
        default:
            throw new Error(`Unexpected entity type ${value}`);
    }
}

export class Entity extends Record {
    constructor(EntityId, EntityType) {
        super();
        this.EntityId = EntityId;
        this.EntityType = EntityType;
    }
}

export function Entity_$reflection() {
    return record_type("Shared.Changelog.Entity", [], Entity, () => [["EntityId", string_type], ["EntityType", EntityType_$reflection()]]);
}

export class Changelog extends Record {
    constructor(Id, Entity, NewValueReferencedEntity, OldValueReferencedEntity, ReferencedEntity, Event$, NewValue, OldValue, Timestamp, UpdatedByUserId) {
        super();
        this.Id = Id;
        this.Entity = Entity;
        this.NewValueReferencedEntity = NewValueReferencedEntity;
        this.OldValueReferencedEntity = OldValueReferencedEntity;
        this.ReferencedEntity = ReferencedEntity;
        this.Event = Event$;
        this.NewValue = NewValue;
        this.OldValue = OldValue;
        this.Timestamp = Timestamp;
        this.UpdatedByUserId = UpdatedByUserId;
    }
}

export function Changelog_$reflection() {
    return record_type("Shared.Changelog.Changelog", [], Changelog, () => [["Id", ChangelogId_$reflection()], ["Entity", Entity_$reflection()], ["NewValueReferencedEntity", option_type(Entity_$reflection())], ["OldValueReferencedEntity", option_type(Entity_$reflection())], ["ReferencedEntity", option_type(Entity_$reflection())], ["Event", ChangedProperty_$reflection()], ["NewValue", string_type], ["OldValue", string_type], ["Timestamp", class_type("System.DateTime")], ["UpdatedByUserId", string_type]]);
}

export class ChangelogEntityDto extends Record {
    constructor(EntityName, EntityId, EntityType) {
        super();
        this.EntityName = EntityName;
        this.EntityId = EntityId;
        this.EntityType = EntityType;
    }
}

export function ChangelogEntityDto_$reflection() {
    return record_type("Shared.Changelog.ChangelogEntityDto", [], ChangelogEntityDto, () => [["EntityName", string_type], ["EntityId", string_type], ["EntityType", EntityType_$reflection()]]);
}

export class ChangelogDto extends Record {
    constructor(Id, ChangedProperty, NewValue, OldValue, NewValueReferencedEntity, OldValueReferencedEntity, ReferencedEntity, Timestamp, UpdatedByUser) {
        super();
        this.Id = Id;
        this.ChangedProperty = ChangedProperty;
        this.NewValue = NewValue;
        this.OldValue = OldValue;
        this.NewValueReferencedEntity = NewValueReferencedEntity;
        this.OldValueReferencedEntity = OldValueReferencedEntity;
        this.ReferencedEntity = ReferencedEntity;
        this.Timestamp = Timestamp;
        this.UpdatedByUser = UpdatedByUser;
    }
}

export function ChangelogDto_$reflection() {
    return record_type("Shared.Changelog.ChangelogDto", [], ChangelogDto, () => [["Id", ChangelogId_$reflection()], ["ChangedProperty", string_type], ["NewValue", string_type], ["OldValue", string_type], ["NewValueReferencedEntity", option_type(ChangelogEntityDto_$reflection())], ["OldValueReferencedEntity", option_type(ChangelogEntityDto_$reflection())], ["ReferencedEntity", option_type(ChangelogEntityDto_$reflection())], ["Timestamp", class_type("System.DateTime")], ["UpdatedByUser", anonRecord_type(["Name", string_type], ["UserId", string_type])]]);
}

export function Helper_changelogMessage(changelog) {
    if (changelog.OldValue === "") {
        return changelog.NewValue;
    }
    else {
        return `${changelog.OldValue} -> ${changelog.NewValue}`;
    }
}

export function Helper_displayHistoryComment(eventName, comment) {
    if (comment === "") {
        return eventName;
    }
    else {
        return `${eventName}: ${comment}`;
    }
}

