import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { toString, compare, utcNow, date } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { append as append_1, choose, collect, reduce, isEmpty, contains, filter, singleton as singleton_1, map as map_1, sum, item, empty as empty_1, take, length, tryHead, ofArray, toArray, sortByDescending } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { map, empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { DeleteReminder_OpenDialog, ConfirmDialog_ConfirmReminderDialog, EditReminder_OpenDialog, NewReminder_OpenDialog } from "../Dialog/AddReminderDialog.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { StorageId as StorageId_2, ConsumableId as ConsumableId_1 } from "../../Shared/Shared.js";
import { Msg } from "./Types.js";
import { map as map_2, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { ColoredDot } from "../../Components/ColoredDot.js";
import { stringHash, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { replace, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import Calendar_Warning from "../../../public/assets/icons/Calendar_Warning.svg";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import Calendar_Add from "../../../public/assets/icons/Calendar_Add.svg";
import Pencil from "../../../public/assets/icons/Pencil.svg";
import Check from "../../../public/assets/icons/Check.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { TextButton, SidebarTextButton, Button } from "../../Components/Button.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { NewReservation_NewConsumableReservationDialog } from "../Dialog/ReservationFormDialog.js";
import { EditConsumableReservationDialog } from "../Dialog/EditReservationFormDialog.js";
import { DeleteConsumableReservationDialog } from "../Dialog/DeleteReservationDialog.js";
import { TextValue } from "../../Components/Text.js";
import { Helper_historyTypeToTranslationString, Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { QuickOverviewList } from "../../Widgets/QuickOverviewList.js";
import { AssignConsumableDialog } from "../Dialog/AssignConsumableDialog.js";
import { UnassignConsumableDialog } from "../Dialog/UnassignConsumableDialog.js";
import Following from "../../../public/assets/icons/Following.svg";
import Followers from "../../../public/assets/icons/Followers.svg";
import { NotificationArea } from "../../Widgets/Notification/NotificationArea.js";
import { NotificationToolType } from "../../Widgets/Notification/AcceptNotificationDialog.js";
import { ImageWidget } from "../../Widgets/ImageWidget.js";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { CustomPropertiesPropertyList } from "../../Widgets/CustomPropertiesPropertyList.js";
import { getQueryParams, priceToString } from "../../Common.js";
import { DocumentTable } from "../../Widgets/Table/DocumentTable.js";
import { UploadType as UploadType_2 } from "../../Widgets/Dialogs/FileUploadDialog.js";
import { Helper_displayHistoryComment } from "../../Shared/Changelog.js";
import { unwrapStorageId } from "../../Shared/Helper.js";
import { ChangeStockDialog } from "../Dialog/StockChangeDialog.js";
import { ChangeStorageSpaceDialog } from "../Dialog/ChangeStorageSpaceDialog.js";
import { SwitchStorageDialog } from "../Dialog/SwitchStorageDialog.js";
import { MinimumStockQuantityDialog } from "../Dialog/MinimumStockQuantityDialog.js";
import { ConsumablesStockPerStorageDeleteDialog } from "../Dialog/ConsumableDeleteStockPerStorageDialog.js";
import Warehouse_1 from "../../../public/assets/icons/Warehouse_1.svg";
import Location_Building_Search from "../../../public/assets/icons/Location_Building_Search.svg";
import Transfer_Cart from "../../../public/assets/icons/Transfer_Cart.svg";
import Notification_Alarm_1 from "../../../public/assets/icons/Notification_Alarm_1.svg";
import { QrCodeTableWithStorage } from "../../Widgets/Table/QrCodeTable.js";
import { ConsumableDeleteDialog } from "../Dialog/ConsumableDeleteDialog.js";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { Badge } from "../../Components/Badge.js";
import { TableContainer, SectionContainer } from "../../Widgets/Container.js";
import { update as update_1, initViewConsumable } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function AppointmentSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsReminderDialogOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setSelectedReminderToConfirm = patternInput_2[1];
    const selectedReminderToConfirm = patternInput_2[0];
    const patternInput_3 = reactApi.useState(false);
    const setIsModalOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedReminder = patternInput_4[1];
    const selectedReminder = patternInput_4[0];
    const patternInput_5 = reactApi.useState(undefined);
    const setSelectedDeleteReminder = patternInput_5[1];
    const selectedDeleteReminder = patternInput_5[0];
    const now = date(utcNow());
    const reminders = sortByDescending((r) => r.Date, props.Consumable.Reminders, {
        Compare: compare,
    });
    const xs_3 = toList(delay(() => append(singleton(createElement(NewReminder_OpenDialog, {
        ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsReminderDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(4, []));
        },
    })), delay(() => {
        let IsOpen;
        return append(singleton(createElement(EditReminder_OpenDialog, (IsOpen = (selectedReminder != null), {
            ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
            IsOpen: IsOpen,
            OnClose: () => {
                setSelectedReminder(undefined);
            },
            Reminder: unwrap(selectedReminder),
            SuccessCallback: () => {
                props.Dispatch(new Msg(4, []));
            },
        }))), delay(() => {
            let matchValue, reminder;
            return append((matchValue = selectedReminderToConfirm, (matchValue == null) ? (empty()) : ((reminder = matchValue, append(singleton(createElement(ConfirmDialog_ConfirmReminderDialog, {
                ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                IsOpen: selectedReminderToConfirm != null,
                OnClose: () => {
                    setSelectedReminderToConfirm(undefined);
                },
                Reminder: reminder,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(4, []));
                },
            })), delay(() => singleton(defaultOf())))))), delay(() => {
                let IsOpen_1;
                return append((selectedDeleteReminder != null) ? singleton(createElement(DeleteReminder_OpenDialog, (IsOpen_1 = (selectedDeleteReminder != null), {
                    ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                    IsOpen: IsOpen_1,
                    OnClose: () => {
                        setSelectedDeleteReminder(undefined);
                    },
                    Reminder: unwrap(selectedDeleteReminder),
                    SuccessCallback: () => {
                        props.Dispatch(new Msg(4, []));
                    },
                }))) : empty(), delay(() => {
                    let elems_2;
                    const table = (rows) => {
                        let TableRows, Options;
                        return createElement(Table, (TableRows = toArray(rows), (Options = {
                            BorderStyle: "row",
                            CSVDownload: false,
                            ConfigurableColumns: false,
                            DefaultSortColumn: ["startDate", "asc"],
                            GlobalSearch: false,
                            RowSelection: "singleRow",
                        }, {
                            ColumnDefinitions: [new ColumnDefinition$1("state", "", t("general.done"), (reminderDate) => {
                                let elems;
                                return reminderDate.IsConfirmed ? (new CellContent(1, [createElement(ColoredDot, {
                                    Color: "green",
                                })])) : ((compare(reminderDate.Date, now) > 0) ? (new CellContent(1, [createElement(ColoredDot, {
                                    Color: "orange",
                                })])) : (new CellContent(1, [createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems = [Calendar_Warning()], ["children", reactApi.Children.toArray(Array.from(elems))])])))])));
                            }, "none", "text"), new ColumnDefinition$1("date", "", t("general.date"), (dto) => (new CellContent(5, [dto.Date])), "dateRange", "date"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Title])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_2) => (new CellContent(0, [dto_2.Description])), "none", "text")],
                            Controls: toList(delay(() => {
                                let matchValue_1;
                                return append(isFeatureEnabled(new Feature(12, [])) ? ((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                                    setIsReminderDialogOpen(true);
                                }, false, false, "table-reminder")) : ((matchValue_1 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                                    setIsReminderDialogOpen(true);
                                }, false, false, "table-reminder")) : ((matchValue_1 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                                    setIsReminderDialogOpen(true);
                                }, false, false, "table-reminder")))))) : empty(), delay(() => {
                                    let matchValue_2;
                                    return append((matchValue_2 = props.UserData.Role, (matchValue_2 === "administrator") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                        const selectedRow = tryHead(rows_1);
                                        if (selectedRow == null) {
                                        }
                                        else {
                                            const planning = selectedRow;
                                            const matchValue_3 = props.UserData.Role;
                                            switch (matchValue_3) {
                                                case "user": {
                                                    break;
                                                }
                                                default:
                                                    setSelectedReminder(planning);
                                            }
                                        }
                                    }, true, false, "table-edit")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                        const selectedRow = tryHead(rows_1);
                                        if (selectedRow == null) {
                                        }
                                        else {
                                            const planning = selectedRow;
                                            const matchValue_3 = props.UserData.Role;
                                            switch (matchValue_3) {
                                                case "user": {
                                                    break;
                                                }
                                                default:
                                                    setSelectedReminder(planning);
                                            }
                                        }
                                    }, true, false, "table-edit")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                        const selectedRow = tryHead(rows_1);
                                        if (selectedRow == null) {
                                        }
                                        else {
                                            const planning = selectedRow;
                                            const matchValue_3 = props.UserData.Role;
                                            switch (matchValue_3) {
                                                case "user": {
                                                    break;
                                                }
                                                default:
                                                    setSelectedReminder(planning);
                                            }
                                        }
                                    }, true, false, "table-edit"))))), delay(() => {
                                        let matchValue_4;
                                        return append((matchValue_4 = props.UserData.Role, (matchValue_4 === "system") ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                            const selectedRow_1 = tryHead(rows_2);
                                            if (selectedRow_1 != null) {
                                                setSelectedReminderToConfirm(selectedRow_1);
                                            }
                                        }, true, false, "table-mark-as-done")) : ((matchValue_4 === "administrator") ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                            const selectedRow_1 = tryHead(rows_2);
                                            if (selectedRow_1 != null) {
                                                setSelectedReminderToConfirm(selectedRow_1);
                                            }
                                        }, true, false, "table-mark-as-done")) : ((matchValue_4 === "user") ? (empty()) : singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                            const selectedRow_1 = tryHead(rows_2);
                                            if (selectedRow_1 != null) {
                                                setSelectedReminderToConfirm(selectedRow_1);
                                            }
                                        }, true, false, "table-mark-as-done"))))), delay(() => {
                                            const matchValue_5 = props.UserData.Role;
                                            switch (matchValue_5) {
                                                case "user": {
                                                    return empty();
                                                }
                                                default:
                                                    return singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_3) => {
                                                        const selectedRow_3 = tryHead(rows_3);
                                                        if (selectedRow_3 == null) {
                                                        }
                                                        else {
                                                            const planning_1 = selectedRow_3;
                                                            const matchValue_6 = props.UserData.Role;
                                                            switch (matchValue_6) {
                                                                case "user": {
                                                                    break;
                                                                }
                                                                default:
                                                                    setSelectedDeleteReminder(planning_1);
                                                            }
                                                        }
                                                    }, true, false, "table-delete"));
                                            }
                                        }));
                                    }));
                                }));
                            })),
                            Options: Options,
                            TableRows: TableRows,
                        })));
                    };
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_2 = toList(delay(() => append(singleton(table((length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                        let elems_1;
                        return (length(reminders) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems_1 = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("general.show_more_data"),
                            OnClick: () => {
                                setIsModalOpen(true);
                            },
                            TestId: "consumable-reminder-showmoredata-button",
                            Variant: "blueButton",
                        }), createElement(ModalSheet, {
                            CloseButtonTestId: "",
                            Content: table(reminders),
                            Controls: empty_1(),
                            IsModalOpen: patternInput_3[0],
                            OnClose: () => {
                                setIsModalOpen(false);
                            },
                        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
                    })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
                }));
            }));
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

function ReservationSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReservation = patternInput_3[1];
    const selectedReservation = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteReservation = patternInput_4[1];
    const selectedDeleteReservation = patternInput_4[0];
    const now = date(utcNow());
    const reservations = sortByDescending((r) => r.StartDate, props.Consumable.Reservations, {
        Compare: compare,
    });
    const xs_2 = toList(delay(() => append(singleton(createElement(NewReservation_NewConsumableReservationDialog, {
        Consumable: props.Consumable,
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(4, []));
        },
        UserData: props.UserData,
    })), delay(() => {
        let matchValue, reservation, IsOpen;
        return append((matchValue = selectedReservation, (matchValue == null) ? (empty()) : ((reservation = matchValue, singleton(createElement(EditConsumableReservationDialog, (IsOpen = (selectedReservation != null), {
            ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
            IsOpen: IsOpen,
            OnClose: () => {
                setSelectedReservation(undefined);
            },
            ReservationId: reservation.Id,
            SuccessCallback: () => {
                props.Dispatch(new Msg(4, []));
            },
            UserData: props.UserData,
        })))))), delay(() => append((selectedDeleteReservation != null) ? singleton(createElement(DeleteConsumableReservationDialog, {
            Consumable: props.Consumable,
            IsOpen: selectedDeleteReservation != null,
            OnClose: () => {
                setSelectedDeleteReservation(undefined);
            },
            Reservation: unwrap(selectedDeleteReservation),
            SuccessCallback: () => {
                props.Dispatch(new Msg(4, []));
            },
            UserData: props.UserData,
        })) : empty(), delay(() => {
            let elems_1;
            const table = (rows) => {
                let TableRows, Options;
                return createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["startDate", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto) => (new CellContent(5, [dto.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_1) => (new CellContent(5, [dto_1.EndDate])), "dateRange", "date"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_2) => (new CellContent(0, [dto_2.ReceiverInformation.ReceiverName])), "none", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_3) => (new CellContent(0, [dto_3.Quantity.toString()])), "text", "text"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_4) => (new CellContent(0, [dto_4.StorageName])), "select", "text"), new ColumnDefinition$1("reservationName", "", t("general.name"), (dto_5) => (new CellContent(0, [dto_5.Name])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_6) => (new CellContent(0, [dto_6.Description])), "none", "text")],
                    Controls: toList(delay(() => append(isFeatureEnabled(new Feature(12, [])) ? singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                        setIsReservationDialogOpen(true);
                    }, false, false, "table-reservation")) : empty(), delay(() => {
                        let matchValue_1;
                        return append((matchValue_1 = props.UserData.Role, (matchValue_1 === "administrator") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit")) : ((matchValue_1 === "toolManager") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit")) : ((matchValue_1 === "user") ? (empty()) : singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit"))))), delay(() => {
                            const matchValue_3 = props.UserData.Role;
                            switch (matchValue_3) {
                                case "user": {
                                    return empty();
                                }
                                default:
                                    return singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_2) => {
                                        const selectedRow_1 = tryHead(rows_2);
                                        if (selectedRow_1 == null) {
                                        }
                                        else {
                                            const planning_1 = selectedRow_1;
                                            const matchValue_4 = props.UserData.Role;
                                            switch (matchValue_4) {
                                                case "user": {
                                                    if (planning_1.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                                        setSelectedDeleteReservation(planning_1);
                                                    }
                                                    break;
                                                }
                                                default:
                                                    setSelectedDeleteReservation(planning_1);
                                            }
                                        }
                                    }, true, false, "table-delete"));
                            }
                        }));
                    })))),
                    Options: Options,
                    TableRows: TableRows,
                })));
            };
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(reservations) > 5) ? take(5, reservations) : reservations)), delay(() => {
                let elems;
                return (length(reservations) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("general.show_more_data"),
                    OnClick: () => {
                        setIsModalOpen(true);
                    },
                    TestId: "consumable-reservations-showmoredata-button",
                    Variant: "blueButton",
                }), createElement(ModalSheet, {
                    CloseButtonTestId: "",
                    Content: table(reservations),
                    Controls: empty_1(),
                    IsModalOpen: patternInput_1[0],
                    OnClose: () => {
                        setIsModalOpen(false);
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
            })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
        }))));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function AnchorMenu(props) {
    let elems_1, elems;
    const scrollOptions = new (class {
        get behavior() {
            return "smooth";
        }
        set behavior(_arg) {
        }
        get block() {
            return "center";
        }
        set block(_arg_1) {
        }
        get inline() {
            return "center";
        }
        set inline(_arg_2) {
        }
    }
    )();
    let patternInput;
    const initial = (length(props.MenuItems) !== 0) ? item(0, props.MenuItems).AnchorId : "default";
    patternInput = reactApi.useState(initial);
    return createElement("div", createObj(ofArray([["className", join(" ", ["sticky", "top-5"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2", "sticky", "top-5"])], (elems = toList(delay(() => map((menu) => createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: menu.DisplayName,
        OnClick: () => {
            patternInput[1](menu.AnchorId);
            document.getElementById(menu.AnchorId).scrollIntoView(scrollOptions);
        },
        TestId: `consumables-anchormenu-${menu.DisplayName}-anchor`,
        Variant: (patternInput[0] === menu.AnchorId) ? "blueButton" : "default",
    }), props.MenuItems))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function OverviewInformationSection(props) {
    let elems_6;
    const t = useTranslation()[0];
    const countOfUnits = sum(map_1((stockPerStorage) => stockPerStorage.Stock, props.Consumable.StockPerStorage), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    });
    const countOfUntisString = replace(countOfUnits.toString(), ".", ",");
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "ml-5"])], (elems_6 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["min-w-full", "text-sm", "font-semibold", "mb-2"])], (elems = [t("general.name")], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton(createElement("div", {
            className: join(" ", ["mb-2"]),
            children: reactApi.Children.toArray([props.Consumable.MasterData.Name]),
        })), delay(() => {
            let elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["min-w-full", "text-sm", "font-semibold", "mb-2"])], (elems_2 = [t("general.stock")], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4, elems_3;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-1"])], (elems_3 = [createElement(ColoredDot, {
                    Color: (countOfUnits === 0) ? "red" : "green",
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement(TextValue, {
                    FontWeight: "normal",
                    TestId: "",
                    Text: `${countOfUntisString} ${t(Helper_unitToTranslationKey(props.Consumable.Unit))}`,
                })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5, Label, Label_1, Label_2, Label_3, Value_3;
                    return isFeatureEnabled(new Feature(3, [])) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_5 = [createElement(QuickOverviewList, {
                        Elements: {
                            CommercialData: props.Consumable.CommercialData,
                            Custom: ofArray([(Label = t("consumable.additional_name"), {
                                Group: t("general.master_data"),
                                Key: "consumable-additional-name-fav-field",
                                Label: Label,
                                Value: props.Consumable.MasterData.AdditionalName,
                            }), (Label_1 = t("general.description"), {
                                Group: t("general.master_data"),
                                Key: "consumable-description-fav-field",
                                Label: Label_1,
                                Value: props.Consumable.MasterData.Description,
                            }), (Label_2 = t("general.inventory_number"), {
                                Group: t("general.master_data"),
                                Key: "consumable-inventorynumber-fav-field",
                                Label: Label_2,
                                Value: props.Consumable.MasterData.InventoryNumber,
                            }), (Label_3 = t("general.unit"), (Value_3 = t(Helper_unitToTranslationKey(props.Consumable.Unit)), {
                                Group: t("general.master_data"),
                                Key: "consumable-unit-fav-field",
                                Label: Label_3,
                                Value: Value_3,
                            }))]),
                            CustomProperties: props.Consumable.CustomProperties,
                        },
                        IsDialogOpen: props.QuickOverviewDialog.IsOpen,
                        IsLoading: props.QuickOverviewDialog.IsLoading,
                        OnSave: (updatedVisibleKeys) => {
                            props.Dispatch(new Msg(0, [updatedVisibleKeys]));
                        },
                        SetDialogOpen: (_arg) => {
                            props.Dispatch(new Msg(1, []));
                        },
                        UserData: props.UserData,
                        VisibleKeys: props.UserConfiguration.QuickOverviewConfiguration.ConsumableViewVisibleFieldKeys,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty();
                }));
            }));
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function OverviewSection(props) {
    let elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsAssignDialogOpen = patternInput_1[1];
    const isAssignDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsUnassignDialogOpen = patternInput_2[1];
    const isUnassignDialogOpen = patternInput_2[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_4 = toList(delay(() => append(isAssignDialogOpen ? singleton(createElement(AssignConsumableDialog, {
        Consumable: props.Consumable,
        IsOpen: isAssignDialogOpen,
        OnClose: () => {
            setIsAssignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(4, []));
        },
        UserId: props.UserData.UserId,
    })) : empty(), delay(() => append(isUnassignDialogOpen ? singleton(createElement(UnassignConsumableDialog, {
        ConsumableId: props.Consumable.Id,
        IsOpen: isUnassignDialogOpen,
        OnClose: () => {
            setIsUnassignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(4, []));
        },
        UserData: props.UserData,
    })) : empty(), delay(() => {
        let elems_3, elems, elems_2, elems_1, matchValue_1;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "pt-8", "pb-10", "rounded", "px-12"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-4", "mb-12"])], (elems = toList(delay(() => {
            let Label;
            const matchValue = props.UserData.Role;
            switch (matchValue) {
                case "user": {
                    return empty();
                }
                default:
                    return append(singleton(createElement(TextButton, (Label = t("general.assign"), {
                        ComponentState: "enabled",
                        Icon: Following(),
                        Label: Label,
                        OnClick: () => {
                            setIsAssignDialogOpen(true);
                        },
                        TestId: "consumable-overview-assign-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return singleton(createElement(TextButton, (Label_1 = t("general.take_back"), {
                            ComponentState: "enabled",
                            Icon: Followers(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsUnassignDialogOpen(true);
                            },
                            TestId: "consumable-overview-unassign-button",
                            Variant: "default",
                        })));
                    }));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(NotificationArea, {
            NotificationToolType: new NotificationToolType(3, []),
            Notifications: props.Notifications,
            OnUpdate: () => {
                props.Dispatch(new Msg(4, []));
            },
            UserData: props.UserData,
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mt-12"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "min-w-[35%]", "min-h-[50%]", "justify-start", "pl-10", "mr-5"])], (elems_1 = [createElement(ImageWidget, {
            CanEditImage: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? true : ((matchValue_1 === "toolManager") ? true : (!(matchValue_1 === "user")))),
            ImageUrl: props.Consumable.ImageUrl,
            OnDeleteImage: () => {
                props.Dispatch(new Msg(8, []));
            },
            OnUploadImage: (file) => {
                props.Dispatch(new Msg(7, [file]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(OverviewInformationSection, {
            Consumable: props.Consumable,
            Dispatch: props.Dispatch,
            QuickOverviewDialog: props.QuickOverviewDialog,
            UserConfiguration: props.UserConfiguration,
            UserData: props.UserData,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function MasterDataSection(props) {
    let elems_4, elems_3, elems_1, Title, elems, elems_2, Title_2, matchValue, matchValue_1, matchValue_2;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-20", "h-30"])], (elems_3 = [createElement("div", createObj(singleton_1((elems_1 = [createElement(PropertyList, (Title = t("general.master_data"), {
        Properties: ofArray([{
            Label: t("general.name"),
            TestId: "master-data-name",
            Value: props.Consumable.MasterData.Name,
        }, {
            Label: t("consumable.additional_name"),
            TestId: "master-data-additional-name",
            Value: props.Consumable.MasterData.AdditionalName,
        }, {
            Label: t("general.description"),
            TestId: "master-data-description",
            Value: props.Consumable.MasterData.Description,
        }, {
            Label: t("general.inventory_number"),
            TestId: "master-data-inventory-number",
            Value: props.Consumable.MasterData.InventoryNumber,
        }, {
            Label: t("general.unit"),
            TestId: "master-data-unit",
            Value: t(Helper_unitToTranslationKey(props.Consumable.Unit)),
        }]),
        Title: unwrap(Title),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems = [createElement(CustomPropertiesPropertyList, {
        CustomProperties: props.Consumable.CustomProperties,
        Title: t("tool.custom_properties"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))), createElement("div", createObj(singleton_1((elems_2 = [createElement(PropertyList, (Title_2 = t("general.commercial_data"), {
        Properties: ofArray([{
            Label: t("general.supplier"),
            TestId: "commercial-data-supplier",
            Value: props.Consumable.CommercialData.Supplier,
        }, {
            Label: t("general.supplier_article_number"),
            TestId: "commercial-data-supplier-article-number",
            Value: props.Consumable.CommercialData.SupplierArticleNumber,
        }, {
            Label: t("general.purchase_price"),
            TestId: "commercial-data-purchase-price",
            Value: (matchValue = props.Consumable.CommercialData.PurchasePrice, (matchValue == null) ? "" : priceToString(matchValue)),
        }, {
            Label: t("general.purchase_date"),
            TestId: "commercial-data-purchase-date",
            Value: (matchValue_1 = props.Consumable.CommercialData.PurchaseDate, (matchValue_1 == null) ? "" : toString(matchValue_1, "dd.MM.yyyy")),
        }, {
            Label: t("general.gurantee_date"),
            TestId: "commercial-data-guarantee-date",
            Value: (matchValue_2 = props.Consumable.CommercialData.WarrantyDate, (matchValue_2 == null) ? "" : toString(matchValue_2, "dd.MM.yyyy")),
        }, {
            Label: t("general.comment"),
            TestId: "commercial-data-comment",
            Value: props.Consumable.CommercialData.Comment,
        }]),
        Title: unwrap(Title_2),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))));
}

function DocumentSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(DocumentTable, {
        Documents: (length(props.Documents) > 5) ? take(5, props.Documents) : props.Documents,
        OnDownloadFile: (tupledArg) => {
            props.Dispatch(new Msg(20, [tupledArg[0], tupledArg[1]]));
        },
        UploadSuccessCallback: () => {
            props.Dispatch(new Msg(19, []));
        },
        UploadType: new UploadType_2(1, [props.ConsumableId]),
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.Documents) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(DocumentTable, {
                Documents: props.Documents,
                OnDownloadFile: (tupledArg_1) => {
                    props.Dispatch(new Msg(20, [tupledArg_1[0], tupledArg_1[1]]));
                },
                UploadSuccessCallback: () => {
                    props.Dispatch(new Msg(19, []));
                },
                UploadType: new UploadType_2(1, [props.ConsumableId]),
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function HistorySection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsHistoryModalOpen = patternInput_1[1];
    const table = (rows) => {
        let TableRows;
        return createElement(Table, (TableRows = toArray(rows), {
            ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (dto) => (new CellContent(6, [dto.Timestamp])), "none", "dateTime"), new ColumnDefinition$1("eventType", "", t("general.event"), (dto_1) => (new CellContent(0, [t(Helper_historyTypeToTranslationString(dto_1.EventType))])), "select", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_2) => (new CellContent(0, [dto_2.Quantity.toString()])), "text", "text"), new ColumnDefinition$1("commission", "", t("general.commission"), (dto_3) => (new CellContent(0, [dto_3.CommissionNumber])), "text", "text"), new ColumnDefinition$1("comment", "", t("general.comment"), (dto_4) => {
                const matchValue = dto_4.Event;
                return (matchValue == null) ? (new CellContent(0, [t(dto_4.Comment)])) : (new CellContent(0, [Helper_displayHistoryComment(t(matchValue), dto_4.Comment)]));
            }, "none", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (dto_5) => {
                let matchValue_1;
                return new CellContent(0, [(matchValue_1 = dto_5.ReceiverInformation, (matchValue_1 == null) ? "" : matchValue_1.Name)]);
            }, "text", "text"), new ColumnDefinition$1("sender", "", t("general.sender"), (dto_6) => (new CellContent(0, [dto_6.SenderInformation.Name])), "text", "text"), new ColumnDefinition$1("signatureUrl", "", t("general.signature"), (dto_7) => {
                let matchValue_2;
                return new CellContent(4, [(matchValue_2 = dto_7.ReceiverInformation, (matchValue_2 != null) ? defaultArg(map_2((x) => x, matchValue_2.SignatureUrl), "") : "")]);
            }, "none", "image"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_8) => (new CellContent(0, [defaultArg(map_2((storage) => storage.Name, dto_8.StorageInformation), "")])), "text", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["timestamp", "desc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }));
    };
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(props.HistoryRows) > 5) ? take(5, props.HistoryRows) : props.HistoryRows)), delay(() => {
        let elems;
        return (length(props.HistoryRows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsHistoryModalOpen(true);
            },
            TestId: "consumable-masterdata-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(props.HistoryRows),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsHistoryModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function StockSection(props) {
    let elems;
    const t = useTranslation()[0];
    let responsibleStorageIds;
    const matchValue = props.UserData.Role;
    switch (matchValue) {
        case "toolManager": {
            responsibleStorageIds = map_1((storage_1) => unwrapStorageId(storage_1.Id), filter((s) => contains(props.UserData.UserId, s.AssignedUserIds, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), props.Storages));
            break;
        }
        case "user": {
            responsibleStorageIds = empty_1();
            break;
        }
        default:
            responsibleStorageIds = map_1((storage) => unwrapStorageId(storage.Id), props.Storages);
    }
    const containsStorageId = (storageId) => contains(storageId, responsibleStorageIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    });
    const patternInput_1 = reactApi.useState(undefined);
    const setIsDeleteStockPerStorageDialogOpen = patternInput_1[1];
    const isDeleteStockPerStorageDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(undefined);
    const setIsMinimumStockQuantityDialogOpen = patternInput_2[1];
    const isMinimumStockQuantityDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsStockChangeDialogOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState(undefined);
    const setIsChangeStorageSpaceDialogOpen = patternInput_4[1];
    const isChangeStorageSpaceDialogOpen = patternInput_4[0];
    const patternInput_5 = reactApi.useState(undefined);
    const setIsChangeStorageDialogOpen = patternInput_5[1];
    const isChangeStorageDialogOpen = patternInput_5[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = toList(delay(() => {
        let matchValue_8, selectedStorage;
        return append((matchValue_8 = patternInput_3[0], (matchValue_8 == null) ? (empty()) : ((selectedStorage = matchValue_8, singleton(createElement(ChangeStockDialog, {
            ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
            IsOpen: selectedStorage[0],
            OnClose: () => {
                setIsStockChangeDialogOpen(undefined);
            },
            StorageId: unwrap(selectedStorage[1]),
            Storages: filter((storage_2) => containsStorageId(unwrapStorageId(storage_2.Id)), props.Storages),
            SuccessCallback: () => {
                props.Dispatch(new Msg(4, []));
            },
        }))))), delay(() => {
            let matchValue_9, selectedStorageSpace, selectedStorage_2, IsOpen;
            return append((matchValue_9 = isChangeStorageSpaceDialogOpen, (matchValue_9 == null) ? (empty()) : ((selectedStorageSpace = matchValue_9[0], (selectedStorage_2 = matchValue_9[1], singleton(createElement(ChangeStorageSpaceDialog, (IsOpen = (isChangeStorageSpaceDialogOpen != null), {
                ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                IsEditableForUser: true,
                IsOpen: IsOpen,
                OnClose: () => {
                    setIsChangeStorageSpaceDialogOpen(undefined);
                },
                StorageId: new StorageId_2(parse(selectedStorage_2)),
                StorageSpace: selectedStorageSpace,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(4, []));
                },
            }))))))), delay(() => {
                let matchValue_10;
                return append((matchValue_10 = isChangeStorageDialogOpen, (matchValue_10 == null) ? (empty()) : singleton(createElement(SwitchStorageDialog, {
                    Consumable: props.Consumable,
                    IsOpen: isChangeStorageDialogOpen != null,
                    OnClose: () => {
                        setIsChangeStorageDialogOpen(undefined);
                    },
                    SelectedSourceStorageId: matchValue_10,
                    Storages: filter((storage_3) => containsStorageId(unwrapStorageId(storage_3.Id)), props.Storages),
                    SuccessCallback: () => {
                        props.Dispatch(new Msg(4, []));
                    },
                    UserId: props.UserData.UserId,
                }))), delay(() => {
                    let matchValue_11, selectedStorage_3, IsOpen_2;
                    return append((matchValue_11 = isMinimumStockQuantityDialogOpen, (matchValue_11 == null) ? (empty()) : ((selectedStorage_3 = matchValue_11, singleton(createElement(MinimumStockQuantityDialog, (IsOpen_2 = (isMinimumStockQuantityDialogOpen != null), {
                        ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                        IsOpen: IsOpen_2,
                        OnClose: () => {
                            setIsMinimumStockQuantityDialogOpen(undefined);
                        },
                        StorageId: selectedStorage_3,
                        SuccessCallback: () => {
                            props.Dispatch(new Msg(4, []));
                        },
                    })))))), delay(() => {
                        let matchValue_12, selectedStorage_4, IsOpen_3;
                        return append((matchValue_12 = isDeleteStockPerStorageDialogOpen, (matchValue_12 == null) ? (empty()) : ((selectedStorage_4 = matchValue_12, singleton(createElement(ConsumablesStockPerStorageDeleteDialog, (IsOpen_3 = (isDeleteStockPerStorageDialogOpen != null), {
                            ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                            IsOpen: IsOpen_3,
                            OnClose: () => {
                                setIsDeleteStockPerStorageDialogOpen(undefined);
                            },
                            StorageId: selectedStorage_4,
                            SuccessCallback: () => {
                                props.Dispatch(new Msg(4, []));
                            },
                        })))))), delay(() => {
                            let TableRows, Options, matchValue_2;
                            return singleton(createElement(Table, (TableRows = toArray(props.Consumable.StockPerStorage), (Options = {
                                BorderStyle: "row",
                                CSVDownload: false,
                                ConfigurableColumns: false,
                                DefaultSortColumn: ["storage", "asc"],
                                GlobalSearch: false,
                                RowSelection: "singleRow",
                            }, {
                                ColumnDefinitions: [new ColumnDefinition$1("storage", "", t("general.storage"), (dto) => (new CellContent(0, [dto.StorageName])), "none", "text"), new ColumnDefinition$1("location", "", t("consumable.storage_space"), (dto_1) => (new CellContent(0, [t(dto_1.StorageSpace)])), "none", "text"), new ColumnDefinition$1("quantity", "", t("general.stock"), (dto_2) => (new CellContent(0, [dto_2.Stock.toString()])), "none", "text"), new ColumnDefinition$1("minimum_quantity", "", t("general.minimum_quantity"), (dto_3) => {
                                    const matchValue_1 = dto_3.MinimumStockQuantity;
                                    if (matchValue_1 != null) {
                                        const minimumstockQuantity = matchValue_1;
                                        return new CellContent(0, [minimumstockQuantity.toString()]);
                                    }
                                    else {
                                        return new CellContent(0, ["0"]);
                                    }
                                }, "none", "text"), new ColumnDefinition$1("qrcode", "", t("general.qr_code"), (dto_4) => (new CellContent(0, [isEmpty(dto_4.QRCodeIds) ? "" : reduce((e1, e2) => (`${e1}, ${e2}`), map_1((e) => e, dto_4.QRCodeIds))])), "none", "text")],
                                Controls: (matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                                    if (isEmpty(selectedSps)) {
                                        setIsStockChangeDialogOpen([true, undefined]);
                                    }
                                    else {
                                        const matchValue_3 = tryHead(map_1((selectedTool) => selectedTool.StorageId, selectedSps));
                                        if (matchValue_3 == null) {
                                        }
                                        else {
                                            const selectedStorageId_1 = matchValue_3;
                                            if (containsStorageId(selectedStorageId_1)) {
                                                setIsStockChangeDialogOpen([true, new StorageId_2(parse(selectedStorageId_1))]);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                                    if (isEmpty(selectedSps_1)) {
                                    }
                                    else {
                                        const matchValue_4 = tryHead(map_1((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                                        if (matchValue_4 == null) {
                                        }
                                        else {
                                            const selectedStorageId_3 = matchValue_4;
                                            if (containsStorageId(selectedStorageId_3[1])) {
                                                setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                                    if (isEmpty(selectedSps_2)) {
                                    }
                                    else {
                                        const matchValue_5 = tryHead(map_1((selectedStockPerStorage) => selectedStockPerStorage.StorageId, selectedSps_2));
                                        if (matchValue_5 == null) {
                                        }
                                        else {
                                            const selectedStorageId_5 = matchValue_5;
                                            if (containsStorageId(selectedStorageId_5)) {
                                                setIsChangeStorageDialogOpen(selectedStorageId_5);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_3) => {
                                    if (isEmpty(selectedSps_3)) {
                                    }
                                    else {
                                        const matchValue_6 = tryHead(map_1((selectedTool_2) => selectedTool_2.StorageId, selectedSps_3));
                                        if (matchValue_6 == null) {
                                        }
                                        else {
                                            const selectedStorageId_7 = matchValue_6;
                                            if (containsStorageId(selectedStorageId_7)) {
                                                setIsMinimumStockQuantityDialogOpen(new StorageId_2(parse(selectedStorageId_7)));
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_4) => {
                                    if (isEmpty(selectedSps_4)) {
                                    }
                                    else {
                                        const matchValue_7 = tryHead(map_1((selectedTool_3) => selectedTool_3.StorageId, selectedSps_4));
                                        if (matchValue_7 == null) {
                                        }
                                        else {
                                            const selectedStorageId_9 = matchValue_7;
                                            if (containsStorageId(selectedStorageId_9)) {
                                                setIsDeleteStockPerStorageDialogOpen(new StorageId_2(parse(selectedStorageId_9)));
                                            }
                                        }
                                    }
                                }, true, false, "")]) : ((matchValue_2 === "toolManager") ? ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                                    if (isEmpty(selectedSps)) {
                                        setIsStockChangeDialogOpen([true, undefined]);
                                    }
                                    else {
                                        const matchValue_3 = tryHead(map_1((selectedTool) => selectedTool.StorageId, selectedSps));
                                        if (matchValue_3 == null) {
                                        }
                                        else {
                                            const selectedStorageId_1 = matchValue_3;
                                            if (containsStorageId(selectedStorageId_1)) {
                                                setIsStockChangeDialogOpen([true, new StorageId_2(parse(selectedStorageId_1))]);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                                    if (isEmpty(selectedSps_1)) {
                                    }
                                    else {
                                        const matchValue_4 = tryHead(map_1((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                                        if (matchValue_4 == null) {
                                        }
                                        else {
                                            const selectedStorageId_3 = matchValue_4;
                                            if (containsStorageId(selectedStorageId_3[1])) {
                                                setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                                    if (isEmpty(selectedSps_2)) {
                                    }
                                    else {
                                        const matchValue_5 = tryHead(map_1((selectedStockPerStorage) => selectedStockPerStorage.StorageId, selectedSps_2));
                                        if (matchValue_5 == null) {
                                        }
                                        else {
                                            const selectedStorageId_5 = matchValue_5;
                                            if (containsStorageId(selectedStorageId_5)) {
                                                setIsChangeStorageDialogOpen(selectedStorageId_5);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_3) => {
                                    if (isEmpty(selectedSps_3)) {
                                    }
                                    else {
                                        const matchValue_6 = tryHead(map_1((selectedTool_2) => selectedTool_2.StorageId, selectedSps_3));
                                        if (matchValue_6 == null) {
                                        }
                                        else {
                                            const selectedStorageId_7 = matchValue_6;
                                            if (containsStorageId(selectedStorageId_7)) {
                                                setIsMinimumStockQuantityDialogOpen(new StorageId_2(parse(selectedStorageId_7)));
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_4) => {
                                    if (isEmpty(selectedSps_4)) {
                                    }
                                    else {
                                        const matchValue_7 = tryHead(map_1((selectedTool_3) => selectedTool_3.StorageId, selectedSps_4));
                                        if (matchValue_7 == null) {
                                        }
                                        else {
                                            const selectedStorageId_9 = matchValue_7;
                                            if (containsStorageId(selectedStorageId_9)) {
                                                setIsDeleteStockPerStorageDialogOpen(new StorageId_2(parse(selectedStorageId_9)));
                                            }
                                        }
                                    }
                                }, true, false, "")]) : ((matchValue_2 === "user") ? empty_1() : ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                                    if (isEmpty(selectedSps)) {
                                        setIsStockChangeDialogOpen([true, undefined]);
                                    }
                                    else {
                                        const matchValue_3 = tryHead(map_1((selectedTool) => selectedTool.StorageId, selectedSps));
                                        if (matchValue_3 == null) {
                                        }
                                        else {
                                            const selectedStorageId_1 = matchValue_3;
                                            if (containsStorageId(selectedStorageId_1)) {
                                                setIsStockChangeDialogOpen([true, new StorageId_2(parse(selectedStorageId_1))]);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                                    if (isEmpty(selectedSps_1)) {
                                    }
                                    else {
                                        const matchValue_4 = tryHead(map_1((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                                        if (matchValue_4 == null) {
                                        }
                                        else {
                                            const selectedStorageId_3 = matchValue_4;
                                            if (containsStorageId(selectedStorageId_3[1])) {
                                                setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                                    if (isEmpty(selectedSps_2)) {
                                    }
                                    else {
                                        const matchValue_5 = tryHead(map_1((selectedStockPerStorage) => selectedStockPerStorage.StorageId, selectedSps_2));
                                        if (matchValue_5 == null) {
                                        }
                                        else {
                                            const selectedStorageId_5 = matchValue_5;
                                            if (containsStorageId(selectedStorageId_5)) {
                                                setIsChangeStorageDialogOpen(selectedStorageId_5);
                                            }
                                        }
                                    }
                                }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_3) => {
                                    if (isEmpty(selectedSps_3)) {
                                    }
                                    else {
                                        const matchValue_6 = tryHead(map_1((selectedTool_2) => selectedTool_2.StorageId, selectedSps_3));
                                        if (matchValue_6 == null) {
                                        }
                                        else {
                                            const selectedStorageId_7 = matchValue_6;
                                            if (containsStorageId(selectedStorageId_7)) {
                                                setIsMinimumStockQuantityDialogOpen(new StorageId_2(parse(selectedStorageId_7)));
                                            }
                                        }
                                    }
                                }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_4) => {
                                    if (isEmpty(selectedSps_4)) {
                                    }
                                    else {
                                        const matchValue_7 = tryHead(map_1((selectedTool_3) => selectedTool_3.StorageId, selectedSps_4));
                                        if (matchValue_7 == null) {
                                        }
                                        else {
                                            const selectedStorageId_9 = matchValue_7;
                                            if (containsStorageId(selectedStorageId_9)) {
                                                setIsDeleteStockPerStorageDialogOpen(new StorageId_2(parse(selectedStorageId_9)));
                                            }
                                        }
                                    }
                                }, true, false, "")])))),
                                Options: Options,
                                TableRows: TableRows,
                            }))));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function BarcodeSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    let tableData;
    const list_6 = collect((x) => x, map_1((stockPerStorage) => choose((qrCodeId) => ({
        QrCode: qrCodeId,
        StorageId: new StorageId_2(parse(stockPerStorage.StorageId)),
        StorageName: stockPerStorage.StorageName,
        StorageSpace: stockPerStorage.StorageSpace,
    }), stockPerStorage.QRCodeIds), props.StockPerStorage));
    tableData = append_1(choose((stockPerStorage_2) => ({
        QrCode: "",
        StorageId: new StorageId_2(parse(stockPerStorage_2.StorageId)),
        StorageName: stockPerStorage_2.StorageName,
        StorageSpace: stockPerStorage_2.StorageSpace,
    }), filter((stockPerStorage_1) => isEmpty(stockPerStorage_1.QRCodeIds), props.StockPerStorage)), list_6);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(QrCodeTableWithStorage, {
        Data: (length(tableData) > 5) ? take(5, tableData) : tableData,
        OnAssignQrCode: (tupledArg) => {
            props.Dispatch(new Msg(11, [tupledArg[0], tupledArg[1]]));
        },
        OnDeleteQrCodes: (tupledArg_1) => {
            props.Dispatch(new Msg(13, [tupledArg_1[0], tupledArg_1[1]]));
        },
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(tableData) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(QrCodeTableWithStorage, {
                Data: tableData,
                OnAssignQrCode: (value_2) => {
                },
                OnDeleteQrCodes: (value_3) => {
                },
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ConsumableDetailOverview(props) {
    let elems_13, elems_12, elems, matchValue, matchValue_1, matchValue_2, elems_1, elems_11, elems_10;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_1[1];
    const isDeleteDialogOpen = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "flex-col"])], (elems_13 = [createElement(ConsumableDeleteDialog, {
        ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
        IsOpen: isDeleteDialogOpen,
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        SuccessCallback: () => {
            RouterModule_nav(ofArray(["consumables"]), 1, 1);
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]", "print:flex", "print:flex-col"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, {
        Badges: map_1((category) => createElement(Badge, {
            Color: category.ColorHex,
            Label: category.CategoryName,
            TestId: "1-badge-test-id",
        }), props.Consumable.Categories),
        OnBack: () => {
            RouterModule_nav(singleton_1("consumables" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
        },
        OnDelete: unwrap((matchValue = props.UserData.Role, (matchValue === "system") ? (() => {
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        }) : ((matchValue === "toolManager") ? undefined : ((matchValue === "user") ? undefined : (() => {
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        }))))),
        OnDuplicate: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (() => {
            RouterModule_nav(ofArray(["consumables", props.Consumable.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["consumables", props.Consumable.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["consumables", props.Consumable.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        OnEdit: unwrap((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? (() => {
            RouterModule_nav(ofArray(["consumables", "edit", props.Consumable.Id + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_2 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["consumables", "edit", props.Consumable.Id + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_2 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["consumables", "edit", props.Consumable.Id + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        Title: props.Consumable.MasterData.Name,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems_1 = [createElement(AnchorMenu, {
        MenuItems: toList(delay(() => append(singleton({
            AnchorId: "consumable-overview-section",
            DisplayName: t("general.overview"),
        }), delay(() => append(singleton({
            AnchorId: "consumable-masterdata-section",
            DisplayName: t("general.master_data"),
        }), delay(() => append(isFeatureEnabled(new Feature(12, [])) ? append(singleton({
            AnchorId: "appointments-section-id",
            DisplayName: t("general.appointments"),
        }), delay(() => singleton({
            AnchorId: "reservations-section-id",
            DisplayName: t("navbar.reservation"),
        }))) : empty(), delay(() => append(singleton({
            AnchorId: "consumable-stock-section",
            DisplayName: t("general.stock"),
        }), delay(() => append(singleton({
            AnchorId: "consumable-documents-section",
            DisplayName: t("general.documents"),
        }), delay(() => append(singleton({
            AnchorId: "barcodes-section-id",
            DisplayName: t("general.qr_code"),
        }), delay(() => {
            const matchValue_3 = props.UserData.Role;
            switch (matchValue_3) {
                case "user": {
                    return empty();
                }
                default:
                    return singleton({
                        AnchorId: "consumable-history-section",
                        DisplayName: t("general.history"),
                    });
            }
        })))))))))))))),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(singleton_1((elems_11 = [createElement("div", createObj(singleton_1((elems_10 = toList(delay(() => {
        let elems_2;
        return append(singleton(createElement("div", createObj(ofArray([["id", "consumable-overview-section"], (elems_2 = [createElement(OverviewSection, {
            Consumable: props.Consumable,
            Dispatch: props.Dispatch,
            Notifications: props.Notifications,
            QuickOverviewDialog: props.QuickOverviewDialog,
            Storages: props.Storages,
            UserConfiguration: props.UserConfiguration,
            UserData: props.UserData,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3, Title_1;
            return append(singleton(createElement("section", createObj(ofArray([["id", "consumable-masterdata-section"], (elems_3 = [createElement(SectionContainer, (Title_1 = t("general.master_data"), {
                Content: createElement(MasterDataSection, {
                    Consumable: props.Consumable,
                }),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_4, Title_2;
                return append(isFeatureEnabled(new Feature(12, [])) ? singleton(createElement("section", createObj(ofArray([["id", "appointments-section-id"], (elems_4 = [createElement(TableContainer, (Title_2 = t("general.appointments"), {
                    Content: createElement(AppointmentSection, {
                        Consumable: props.Consumable,
                        Dispatch: props.Dispatch,
                        ReceiverNames: empty_1(),
                        UserData: props.UserData,
                    }),
                    Title: unwrap(Title_2),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))) : empty(), delay(() => {
                    let elems_5, Title_3;
                    return append(isFeatureEnabled(new Feature(12, [])) ? singleton(createElement("section", createObj(ofArray([["id", "reservations-section-id"], (elems_5 = [createElement(TableContainer, (Title_3 = t("navbar.reservation"), {
                        Content: createElement(ReservationSection, {
                            Consumable: props.Consumable,
                            Dispatch: props.Dispatch,
                            ReceiverNames: empty_1(),
                            UserData: props.UserData,
                        }),
                        Title: unwrap(Title_3),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty(), delay(() => {
                        let elems_6, Title_4;
                        return append(singleton(createElement("section", createObj(ofArray([["id", "consumable-stock-section"], (elems_6 = [createElement(TableContainer, (Title_4 = t("general.stock"), {
                            Content: createElement(StockSection, {
                                Consumable: props.Consumable,
                                Dispatch: props.Dispatch,
                                Storages: props.Storages,
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_4),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                            let elems_7, Title_5;
                            return append(singleton(createElement("section", createObj(ofArray([["id", "consumable-documents-section"], (elems_7 = [createElement(TableContainer, (Title_5 = t("general.documents"), {
                                Content: createElement(DocumentSection, {
                                    ConsumableId: new ConsumableId_1(parse(props.Consumable.Id)),
                                    Dispatch: props.Dispatch,
                                    Documents: props.Documents,
                                    IsVisibleForUser: true,
                                    UserData: props.UserData,
                                }),
                                Title: unwrap(Title_5),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                                let elems_8, Title_6;
                                return append(singleton(createElement("section", createObj(ofArray([["id", "barcodes-section-id"], (elems_8 = [createElement(TableContainer, (Title_6 = t("general.qr_code"), {
                                    Content: createElement(BarcodeSection, {
                                        Dispatch: props.Dispatch,
                                        StockPerStorage: props.Consumable.StockPerStorage,
                                        Storages: props.Storages,
                                        UserData: props.UserData,
                                    }),
                                    Title: unwrap(Title_6),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                                    let elems_9, Title_7;
                                    const matchValue_4 = props.UserData.Role;
                                    switch (matchValue_4) {
                                        case "user": {
                                            return empty();
                                        }
                                        default:
                                            return singleton(createElement("section", createObj(ofArray([["id", "consumable-history-section"], (elems_9 = [createElement(TableContainer, (Title_7 = t("general.history"), {
                                                Content: createElement(HistorySection, {
                                                    HistoryRows: props.History,
                                                }),
                                                Title: unwrap(Title_7),
                                            }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))));
                                    }
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_10))]))))], ["children", reactApi.Children.toArray(Array.from(elems_11))]))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])));
}

export function ConsumableDetailOverviewView(props) {
    let patternInput;
    const init = initViewConsumable(props.ConsumableId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ConsumableId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.Consumables;
    const matchValue_1 = state_1.History;
    const matchValue_2 = state_1.Documents;
    const matchValue_3 = state_1.UserConfiguration;
    const matchValue_4 = state_1.Storages;
    const matchValue_5 = state_1.Notifications;
    let matchResult, consumable, documents, history, notifications, storages, userConfiguration;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumable = matchValue.fields[0];
        documents = matchValue_2.fields[0];
        history = matchValue_1.fields[0];
        notifications = matchValue_5.fields[0];
        storages = matchValue_4.fields[0];
        userConfiguration = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ConsumableDetailOverview, {
                Consumable: consumable,
                Dispatch: patternInput[1],
                Documents: documents,
                History: history,
                Notifications: notifications,
                QuickOverviewDialog: state_1.QuickOverviewDialog,
                Storages: storages,
                UserConfiguration: userConfiguration,
                UserData: state_1.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

