import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, option_type, bool_type, float64_type, int32_type, class_type, union_type, list_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { StocktakingId_$reflection, StocktakingListId_$reflection, ConsumableId_$reflection, ToolId_$reflection, StorageId_$reflection } from "./Shared.js";
import { empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { ChangelogDto_$reflection } from "./Changelog.js";

export class StocktackingUser extends Record {
    constructor(Name, Department, Id) {
        super();
        this.Name = Name;
        this.Department = Department;
        this.Id = Id;
    }
}

export function StocktackingUser_$reflection() {
    return record_type("Shared.Stocktaking.StocktackingUser", [], StocktackingUser, () => [["Name", string_type], ["Department", string_type], ["Id", string_type]]);
}

export class StocktakingLocationScope extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Complete", "Storages", "Receivers"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "storages" : ((this$.tag === 2) ? "receivers" : "complete");
    }
}

export function StocktakingLocationScope_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingLocationScope", [], StocktakingLocationScope, () => [[], [["Item", list_type(StorageId_$reflection())]], [["Item", list_type(string_type)]]]);
}

export function StocktakingLocationScope_Parse_Z721C83C5(value) {
    switch (value) {
        case "complete":
            return new StocktakingLocationScope(0, []);
        case "storages":
            return new StocktakingLocationScope(1, [empty()]);
        case "receivers":
            return new StocktakingLocationScope(2, [empty()]);
        default:
            throw new Error(`Invalid stocktaking location case: ${value}`);
    }
}

export class StocktakingTypeScope extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tools", "ToolsWithQuantity", "Consumables"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "toolsWithQuantity" : ((this$.tag === 2) ? "consumables" : "tools");
    }
}

export function StocktakingTypeScope_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingTypeScope", [], StocktakingTypeScope, () => [[], [], []]);
}

export function StocktakingTypeScope_Parse_Z721C83C5(value) {
    switch (value) {
        case "tools":
            return new StocktakingTypeScope(0, []);
        case "toolsWithQuantity":
            return new StocktakingTypeScope(1, []);
        case "consumables":
            return new StocktakingTypeScope(2, []);
        default:
            throw new Error(`Invalid stocktaking type case: ${value}`);
    }
}

export class StocktakingListState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Planned", "Started", "Done"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "started" : ((this$.tag === 2) ? "done" : "planned");
    }
}

export function StocktakingListState_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingListState", [], StocktakingListState, () => [[], [], []]);
}

export function StocktakingListState__ToI18nString(this$) {
    switch (this$.tag) {
        case 1:
            return "stocktaking.list_state.started";
        case 2:
            return "stocktaking.list_state.done";
        default:
            return "stocktaking.list_state.planned";
    }
}

export function StocktakingListState_Parse_Z721C83C5(value) {
    switch (value) {
        case "planned":
            return new StocktakingListState(0, []);
        case "started":
            return new StocktakingListState(1, []);
        case "done":
            return new StocktakingListState(2, []);
        default:
            throw new Error(`Invalid stocktaking list state case: ${value}`);
    }
}

export class StocktakingListLocation extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Storage", "Receiver"];
    }
}

export function StocktakingListLocation_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingListLocation", [], StocktakingListLocation, () => [[["Item", StorageId_$reflection()]], [["Item", string_type]]]);
}

export class ToolCorrectionType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Retire", "Lost"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "lost" : "retire";
    }
}

export function ToolCorrectionType_$reflection() {
    return union_type("Shared.Stocktaking.ToolCorrectionType", [], ToolCorrectionType, () => [[], []]);
}

export function ToolCorrectionType__ToI18nString(this$) {
    if (this$.tag === 1) {
        return "stocktaking.tool_correction_type.lost";
    }
    else {
        return "stocktaking.tool_correction_type.retire";
    }
}

export function ToolCorrectionType_Parse_Z721C83C5(value) {
    switch (value) {
        case "retire":
            return new ToolCorrectionType(0, []);
        case "lost":
            return new ToolCorrectionType(1, []);
        default:
            throw new Error(`Invalid tool correction type: ${value}`);
    }
}

export class ToolCorrection extends Record {
    constructor(ToolId, CorrectedBy, Timestamp, BookingText, CorrectionType) {
        super();
        this.ToolId = ToolId;
        this.CorrectedBy = CorrectedBy;
        this.Timestamp = Timestamp;
        this.BookingText = BookingText;
        this.CorrectionType = CorrectionType;
    }
}

export function ToolCorrection_$reflection() {
    return record_type("Shared.Stocktaking.ToolCorrection", [], ToolCorrection, () => [["ToolId", ToolId_$reflection()], ["CorrectedBy", string_type], ["Timestamp", class_type("System.DateTime")], ["BookingText", string_type], ["CorrectionType", ToolCorrectionType_$reflection()]]);
}

export class ToolWithQuantityCorrectionType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Lost", "StockUpdate"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "stockUpdate" : "lost";
    }
}

export function ToolWithQuantityCorrectionType_$reflection() {
    return union_type("Shared.Stocktaking.ToolWithQuantityCorrectionType", [], ToolWithQuantityCorrectionType, () => [[], []]);
}

export function ToolWithQuantityCorrectionType_Parse_Z721C83C5(value) {
    switch (value) {
        case "lost":
            return new ToolWithQuantityCorrectionType(0, []);
        case "stockUpdate":
            return new ToolWithQuantityCorrectionType(1, []);
        default:
            throw new Error(`Invalid tool with quantity correction type: ${value}`);
    }
}

export class ToolWithQuantityCorrection extends Record {
    constructor(ToolWithQuantityId, CorrectedBy, Timestamp, BookingText, CorrectionType, Quantity) {
        super();
        this.ToolWithQuantityId = ToolWithQuantityId;
        this.CorrectedBy = CorrectedBy;
        this.Timestamp = Timestamp;
        this.BookingText = BookingText;
        this.CorrectionType = CorrectionType;
        this.Quantity = (Quantity | 0);
    }
}

export function ToolWithQuantityCorrection_$reflection() {
    return record_type("Shared.Stocktaking.ToolWithQuantityCorrection", [], ToolWithQuantityCorrection, () => [["ToolWithQuantityId", ToolId_$reflection()], ["CorrectedBy", string_type], ["Timestamp", class_type("System.DateTime")], ["BookingText", string_type], ["CorrectionType", ToolWithQuantityCorrectionType_$reflection()], ["Quantity", int32_type]]);
}

export class ConsumableCorrectionType extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["StockUpdate"];
    }
    toString() {
        return "stockUpdate";
    }
}

export function ConsumableCorrectionType_$reflection() {
    return union_type("Shared.Stocktaking.ConsumableCorrectionType", [], ConsumableCorrectionType, () => [[]]);
}

export function ConsumableCorrectionType_Parse_Z721C83C5(value) {
    if (value === "stockUpdate") {
        return new ConsumableCorrectionType();
    }
    else {
        throw new Error(`Invalid consumable correction type: ${value}`);
    }
}

export class ConsumableCorrection extends Record {
    constructor(ConsumableId, CorrectedBy, Timestamp, BookingText, CorrectionType, Quantity) {
        super();
        this.ConsumableId = ConsumableId;
        this.CorrectedBy = CorrectedBy;
        this.Timestamp = Timestamp;
        this.BookingText = BookingText;
        this.CorrectionType = CorrectionType;
        this.Quantity = Quantity;
    }
}

export function ConsumableCorrection_$reflection() {
    return record_type("Shared.Stocktaking.ConsumableCorrection", [], ConsumableCorrection, () => [["ConsumableId", ConsumableId_$reflection()], ["CorrectedBy", string_type], ["Timestamp", class_type("System.DateTime")], ["BookingText", string_type], ["CorrectionType", ConsumableCorrectionType_$reflection()], ["Quantity", float64_type]]);
}

export class ToolResult extends Record {
    constructor(Existing, RecordedBy, Timestamp) {
        super();
        this.Existing = Existing;
        this.RecordedBy = RecordedBy;
        this.Timestamp = Timestamp;
    }
}

export function ToolResult_$reflection() {
    return record_type("Shared.Stocktaking.ToolResult", [], ToolResult, () => [["Existing", bool_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class ToolWithQuantityResult extends Record {
    constructor(Quantity, RecordedBy, Timestamp) {
        super();
        this.Quantity = (Quantity | 0);
        this.RecordedBy = RecordedBy;
        this.Timestamp = Timestamp;
    }
}

export function ToolWithQuantityResult_$reflection() {
    return record_type("Shared.Stocktaking.ToolWithQuantityResult", [], ToolWithQuantityResult, () => [["Quantity", int32_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class ConsumableResult extends Record {
    constructor(Quantity, RecordedBy, Timestamp) {
        super();
        this.Quantity = Quantity;
        this.RecordedBy = RecordedBy;
        this.Timestamp = Timestamp;
    }
}

export function ConsumableResult_$reflection() {
    return record_type("Shared.Stocktaking.ConsumableResult", [], ConsumableResult, () => [["Quantity", float64_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class StocktakingListTool extends Record {
    constructor(ToolId, Result, Correction) {
        super();
        this.ToolId = ToolId;
        this.Result = Result;
        this.Correction = Correction;
    }
}

export function StocktakingListTool_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingListTool", [], StocktakingListTool, () => [["ToolId", ToolId_$reflection()], ["Result", option_type(ToolResult_$reflection())], ["Correction", option_type(ToolCorrection_$reflection())]]);
}

export class StocktakingListToolWithQuantity extends Record {
    constructor(ToolId, Quantity, Result, Correction) {
        super();
        this.ToolId = ToolId;
        this.Quantity = (Quantity | 0);
        this.Result = Result;
        this.Correction = Correction;
    }
}

export function StocktakingListToolWithQuantity_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingListToolWithQuantity", [], StocktakingListToolWithQuantity, () => [["ToolId", ToolId_$reflection()], ["Quantity", int32_type], ["Result", option_type(ToolWithQuantityResult_$reflection())], ["Correction", option_type(ToolWithQuantityCorrection_$reflection())]]);
}

export class StocktakingListConsumable extends Record {
    constructor(ConsumableId, Quantity, Result, Correction) {
        super();
        this.ConsumableId = ConsumableId;
        this.Quantity = Quantity;
        this.Result = Result;
        this.Correction = Correction;
    }
}

export function StocktakingListConsumable_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingListConsumable", [], StocktakingListConsumable, () => [["ConsumableId", ConsumableId_$reflection()], ["Quantity", float64_type], ["Result", option_type(ConsumableResult_$reflection())], ["Correction", option_type(ConsumableCorrection_$reflection())]]);
}

export class StocktakingList extends Record {
    constructor(Id, Location, HelperId, Tools, ToolsWithQuantity, Consumables, State) {
        super();
        this.Id = Id;
        this.Location = Location;
        this.HelperId = HelperId;
        this.Tools = Tools;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.Consumables = Consumables;
        this.State = State;
    }
}

export function StocktakingList_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingList", [], StocktakingList, () => [["Id", StocktakingListId_$reflection()], ["Location", StocktakingListLocation_$reflection()], ["HelperId", list_type(string_type)], ["Tools", list_type(StocktakingListTool_$reflection())], ["ToolsWithQuantity", list_type(StocktakingListToolWithQuantity_$reflection())], ["Consumables", list_type(StocktakingListConsumable_$reflection())], ["State", StocktakingListState_$reflection()]]);
}

export class StocktakingCorrectionDto extends Record {
    constructor(BookingText, ToolIds, ToolCorrectionType, ToolWithQuantityIds, ConsumableIds) {
        super();
        this.BookingText = BookingText;
        this.ToolIds = ToolIds;
        this.ToolCorrectionType = ToolCorrectionType;
        this.ToolWithQuantityIds = ToolWithQuantityIds;
        this.ConsumableIds = ConsumableIds;
    }
}

export function StocktakingCorrectionDto_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingCorrectionDto", [], StocktakingCorrectionDto, () => [["BookingText", string_type], ["ToolIds", list_type(ToolId_$reflection())], ["ToolCorrectionType", ToolCorrectionType_$reflection()], ["ToolWithQuantityIds", list_type(ToolId_$reflection())], ["ConsumableIds", list_type(ConsumableId_$reflection())]]);
}

export class StocktakingEvents extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Planned", "Fixed", "Started"];
    }
}

export function StocktakingEvents_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingEvents", [], StocktakingEvents, () => [[], [], []]);
}

export class StocktakingState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Planned", "Fixed", "Started", "Done", "Closed"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "fixed" : ((this$.tag === 2) ? "started" : ((this$.tag === 3) ? "done" : ((this$.tag === 4) ? "closed" : "planned")));
    }
}

export function StocktakingState_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingState", [], StocktakingState, () => [[], [], [], [], []]);
}

export function StocktakingState__ToI18nString(this$) {
    switch (this$.tag) {
        case 1:
            return "stocktaking.state.fixed";
        case 2:
            return "stocktaking.state.started";
        case 3:
            return "stocktaking.state.done";
        case 4:
            return "stocktaking.state.closed";
        default:
            return "stocktaking.state.planned";
    }
}

export function StocktakingState_Parse_Z721C83C5(value) {
    switch (value) {
        case "planned":
            return new StocktakingState(0, []);
        case "fixed":
            return new StocktakingState(1, []);
        case "started":
            return new StocktakingState(2, []);
        case "done":
            return new StocktakingState(3, []);
        case "closed":
            return new StocktakingState(4, []);
        default:
            throw new Error(`Invalid stocktaking state case: ${value}`);
    }
}

export class Stocktaking extends Record {
    constructor(Id, Name, StartDate, EndDate, Description, IsScannerRequired, ToolsCanBeTaken, HelperIds, ResponsiblePersonIds, LocationScope, TypeScope, State, StocktakingList, Events, IsDeleted, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Description = Description;
        this.IsScannerRequired = IsScannerRequired;
        this.ToolsCanBeTaken = ToolsCanBeTaken;
        this.HelperIds = HelperIds;
        this.ResponsiblePersonIds = ResponsiblePersonIds;
        this.LocationScope = LocationScope;
        this.TypeScope = TypeScope;
        this.State = State;
        this.StocktakingList = StocktakingList;
        this.Events = Events;
        this.IsDeleted = IsDeleted;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Stocktaking_$reflection() {
    return record_type("Shared.Stocktaking.Stocktaking", [], Stocktaking, () => [["Id", StocktakingId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Description", string_type], ["IsScannerRequired", bool_type], ["ToolsCanBeTaken", bool_type], ["HelperIds", list_type(string_type)], ["ResponsiblePersonIds", list_type(string_type)], ["LocationScope", StocktakingLocationScope_$reflection()], ["TypeScope", list_type(StocktakingTypeScope_$reflection())], ["State", StocktakingState_$reflection()], ["StocktakingList", list_type(StocktakingList_$reflection())], ["Events", list_type(StocktakingEvents_$reflection())], ["IsDeleted", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class StocktakingListDto extends Record {
    constructor(Id, Location, DisplayName, Helper, Tools, ToolsWithQuantity, Consumables, State, Progress, RequiredActionsCount) {
        super();
        this.Id = Id;
        this.Location = Location;
        this.DisplayName = DisplayName;
        this.Helper = Helper;
        this.Tools = Tools;
        this.ToolsWithQuantity = ToolsWithQuantity;
        this.Consumables = Consumables;
        this.State = State;
        this.Progress = Progress;
        this.RequiredActionsCount = RequiredActionsCount;
    }
}

export function StocktakingListDto_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingListDto", [], StocktakingListDto, () => [["Id", StocktakingListId_$reflection()], ["Location", StocktakingListLocation_$reflection()], ["DisplayName", string_type], ["Helper", list_type(anonRecord_type(["Id", string_type], ["Name", string_type]))], ["Tools", list_type(anonRecord_type(["Correction", option_type(anonRecord_type(["CorrectedBy", string_type], ["Timestamp", class_type("System.DateTime")]))], ["ImageUrl", string_type], ["InventoryNumber", string_type], ["Result", option_type(anonRecord_type(["Existing", bool_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]))], ["StorageSpace", string_type], ["ToolId", ToolId_$reflection()], ["ToolName", string_type]))], ["ToolsWithQuantity", list_type(anonRecord_type(["Correction", option_type(anonRecord_type(["CorrectedBy", string_type], ["Quantity", int32_type], ["Timestamp", class_type("System.DateTime")]))], ["ImageUrl", string_type], ["InventoryNumber", string_type], ["Quantity", int32_type], ["Result", option_type(anonRecord_type(["Quantity", int32_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]))], ["StorageSpace", string_type], ["ToolId", ToolId_$reflection()], ["ToolName", string_type]))], ["Consumables", list_type(anonRecord_type(["ConsumableId", ConsumableId_$reflection()], ["ConsumableName", string_type], ["Correction", option_type(anonRecord_type(["CorrectedBy", string_type], ["Quantity", float64_type], ["Timestamp", class_type("System.DateTime")]))], ["ImageUrl", string_type], ["InventoryNumber", string_type], ["Quantity", float64_type], ["Result", option_type(anonRecord_type(["Quantity", float64_type], ["RecordedBy", string_type], ["Timestamp", class_type("System.DateTime")]))], ["StorageSpace", string_type], ["Unit", string_type]))], ["State", StocktakingListState_$reflection()], ["Progress", option_type(int32_type)], ["RequiredActionsCount", option_type(int32_type)]]);
}

export class StocktakingDetailDto extends Record {
    constructor(Id, Name, Description, State, StartDate, EndDate, ToolsCanBeTaken, Helpers, ResponsiblePersons, StocktakingLists, Changelogs) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.State = State;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ToolsCanBeTaken = ToolsCanBeTaken;
        this.Helpers = Helpers;
        this.ResponsiblePersons = ResponsiblePersons;
        this.StocktakingLists = StocktakingLists;
        this.Changelogs = Changelogs;
    }
}

export function StocktakingDetailDto_$reflection() {
    return record_type("Shared.Stocktaking.StocktakingDetailDto", [], StocktakingDetailDto, () => [["Id", StocktakingId_$reflection()], ["Name", string_type], ["Description", string_type], ["State", StocktakingState_$reflection()], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ToolsCanBeTaken", bool_type], ["Helpers", list_type(StocktackingUser_$reflection())], ["ResponsiblePersons", list_type(StocktackingUser_$reflection())], ["StocktakingLists", list_type(StocktakingListDto_$reflection())], ["Changelogs", list_type(ChangelogDto_$reflection())]]);
}

export class CreateOrUpdateStocktakingDto extends Record {
    constructor(Name, Description, StartDate, EndDate, LocationScope, TypeScope, ToolsCanBeTaken, IsScannerRequired, HelperIds, ResponsiblePersonIds) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.LocationScope = LocationScope;
        this.TypeScope = TypeScope;
        this.ToolsCanBeTaken = ToolsCanBeTaken;
        this.IsScannerRequired = IsScannerRequired;
        this.HelperIds = HelperIds;
        this.ResponsiblePersonIds = ResponsiblePersonIds;
    }
}

export function CreateOrUpdateStocktakingDto_$reflection() {
    return record_type("Shared.Stocktaking.CreateOrUpdateStocktakingDto", [], CreateOrUpdateStocktakingDto, () => [["Name", string_type], ["Description", string_type], ["StartDate", string_type], ["EndDate", string_type], ["LocationScope", StocktakingLocationScope_$reflection()], ["TypeScope", list_type(StocktakingTypeScope_$reflection())], ["ToolsCanBeTaken", bool_type], ["IsScannerRequired", bool_type], ["HelperIds", list_type(string_type)], ["ResponsiblePersonIds", list_type(string_type)]]);
}

export class UpdateStocktakingListHelperDto extends Record {
    constructor(HelperIds) {
        super();
        this.HelperIds = HelperIds;
    }
}

export function UpdateStocktakingListHelperDto_$reflection() {
    return record_type("Shared.Stocktaking.UpdateStocktakingListHelperDto", [], UpdateStocktakingListHelperDto, () => [["HelperIds", list_type(string_type)]]);
}

export class CreateStocktakingListToolResultDto extends Record {
    constructor(Existing) {
        super();
        this.Existing = Existing;
    }
}

export function CreateStocktakingListToolResultDto_$reflection() {
    return record_type("Shared.Stocktaking.CreateStocktakingListToolResultDto", [], CreateStocktakingListToolResultDto, () => [["Existing", bool_type]]);
}

export class CreateStocktakingListToolWithQuantityResultDto extends Record {
    constructor(Quantity) {
        super();
        this.Quantity = (Quantity | 0);
    }
}

export function CreateStocktakingListToolWithQuantityResultDto_$reflection() {
    return record_type("Shared.Stocktaking.CreateStocktakingListToolWithQuantityResultDto", [], CreateStocktakingListToolWithQuantityResultDto, () => [["Quantity", int32_type]]);
}

export class CreateStocktakingListConsumableResultDto extends Record {
    constructor(Quantity) {
        super();
        this.Quantity = Quantity;
    }
}

export function CreateStocktakingListConsumableResultDto_$reflection() {
    return record_type("Shared.Stocktaking.CreateStocktakingListConsumableResultDto", [], CreateStocktakingListConsumableResultDto, () => [["Quantity", float64_type]]);
}

export class StocktakingResultType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Consumable"];
    }
}

export function StocktakingResultType_$reflection() {
    return union_type("Shared.Stocktaking.StocktakingResultType", [], StocktakingResultType, () => [[["Item", ToolId_$reflection()]], [["Item", ToolId_$reflection()]], [["Item", ConsumableId_$reflection()]]]);
}

