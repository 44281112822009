import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { append as append_1, choose, collect, sumBy, reduce, isEmpty, contains, filter, map, singleton as singleton_1, empty as empty_1, take, length, tryHead, ofArray, toArray, sortByDescending } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { toLocalTime, toString, utcNow, date as date_1, compare } from "../../../fable_modules/fable-library-js.4.19.2/Date.js";
import { empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { DeleteReminder_OpenDialog, ConfirmDialog_ConfirmReminderDialog, EditReminder_OpenDialog, NewReminder_OpenDialog } from "../Dialogs/AddReminderDialog.js";
import { Msg } from "./Types.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { StorageId__get_unwrap, StorageId as StorageId_2, ToolId as ToolId_4 } from "../../../Shared/Shared.js";
import { map as map_1, defaultArg, unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../../Widgets/Table/Table.js";
import { ColoredDot } from "../../../Components/ColoredDot.js";
import { safeHash, equals, stringHash, int32ToString, createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import Calendar_Warning from "../../../../public/assets/icons/Calendar_Warning.svg";
import { Feature, isFeatureEnabled } from "../../../FeatureFlip.js";
import Calendar_Add from "../../../../public/assets/icons/Calendar_Add.svg";
import Pencil from "../../../../public/assets/icons/Pencil.svg";
import Check from "../../../../public/assets/icons/Check.svg";
import Recycle_Bin_1 from "../../../../public/assets/icons/Recycle_Bin_1.svg";
import { TextButton, SidebarTextButton, Button } from "../../../Components/Button.js";
import { ModalSheet } from "../../../Components/ModalSheet.js";
import { NewReservation_AddToolWithQuantityReservationDialog } from "../Dialogs/ToolWithQuantityReservationDialog.js";
import { EditToolWithQuantityReservationDialog } from "../Dialogs/EditToolWithQuantityReservationDialog.js";
import { DeleteToolWithQuantityReservationDialog } from "../Dialogs/ToolWithQuantityDeleteReservation.js";
import { Record } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DocumentTable } from "../../../Widgets/Table/DocumentTable.js";
import { UploadType as UploadType_2 } from "../../../Widgets/Dialogs/FileUploadDialog.js";
import Warehouse_1 from "../../../../public/assets/icons/Warehouse_1.svg";
import Location_Building_Search from "../../../../public/assets/icons/Location_Building_Search.svg";
import Transfer_Cart from "../../../../public/assets/icons/Transfer_Cart.svg";
import Notification_Alarm_1 from "../../../../public/assets/icons/Notification_Alarm_1.svg";
import { ToolWithQuantityStockPerStorageDeleteDialog } from "../Dialogs/ToolWithQuantityDeleteStockPerStorageDialog.js";
import { MinimumStockQuantityDialog } from "../Dialogs/MinimumStockQuantityDialog.js";
import { ChangeStockDialog } from "../Dialogs/StockChangeDialog.js";
import { ChangeStorageSpaceDialog } from "../Dialogs/ChangeStorageSpaceDialog.js";
import { ChangeStorageDialog } from "../Dialogs/SwitchStorageDialog.js";
import { Helper_historyTypeToTranslationString } from "../../../Shared/Tool.js";
import { Helper_displayHistoryComment } from "../../../Shared/Changelog.js";
import { PropertyList } from "../../../Widgets/PropertyListWidget.js";
import { CustomPropertiesPropertyList } from "../../../Widgets/CustomPropertiesPropertyList.js";
import { getQueryParams, priceToString } from "../../../Common.js";
import { AssignToolWithQuantityDialog } from "../Dialogs/AssignToolWithQuantityDialog.js";
import { UnassignToolWithQuantityDialog } from "../Dialogs/UnassignToolWithQuantityDialog.js";
import { LostToolWithQuantityDialog } from "../Dialogs/LostToolWithQuantityDialog.js";
import Following from "../../../../public/assets/icons/Following.svg";
import Followers from "../../../../public/assets/icons/Followers.svg";
import Shipment_Search from "../../../../public/assets/icons/Shipment_Search.svg";
import { NotificationArea } from "../../../Widgets/Notification/NotificationArea.js";
import { NotificationToolType } from "../../../Widgets/Notification/AcceptNotificationDialog.js";
import { ImageWidget } from "../../../Widgets/ImageWidget.js";
import { TextValue } from "../../../Components/Text.js";
import { QuickOverviewList } from "../../../Widgets/QuickOverviewList.js";
import { QrCodeTableWithStorage } from "../../../Widgets/Table/QrCodeTable.js";
import { OpenToolDeleteDialog } from "../../Dialogs/ToolDeleteDialog.js";
import { DetailViewHeader } from "../../../Widgets/DetailViewHeader.js";
import { Badge } from "../../../Components/Badge.js";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { TableContainer } from "../../../Widgets/Container.js";
import { update as update_1, initViewToolWithQuantity } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../../Components/Skeleton.js";

function AppointmentsSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsReminderDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReminderToConfirm = patternInput_3[1];
    const selectedReminderToConfirm = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedReminder = patternInput_4[1];
    const selectedReminder = patternInput_4[0];
    const patternInput_5 = reactApi.useState(undefined);
    const setSelectedDeleteReminder = patternInput_5[1];
    const selectedDeleteReminder = patternInput_5[0];
    const reminders = sortByDescending((r) => r.Date, props.ToolWithQuantity.Reminders, {
        Compare: compare,
    });
    const now = date_1(utcNow());
    const xs_3 = toList(delay(() => append(singleton(createElement(NewReminder_OpenDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsReminderDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
    })), delay(() => append(singleton(createElement(EditReminder_OpenDialog, {
        IsOpen: selectedReminder != null,
        OnClose: () => {
            setSelectedReminder(undefined);
        },
        Reminder: unwrap(selectedReminder),
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
    })), delay(() => {
        let matchValue;
        return append((matchValue = selectedReminderToConfirm, (matchValue == null) ? (empty()) : append(singleton(createElement(ConfirmDialog_ConfirmReminderDialog, {
            IsOpen: selectedReminderToConfirm != null,
            OnClose: () => {
                setSelectedReminderToConfirm(undefined);
            },
            Reminder: matchValue,
            SuccessCallback: () => {
                props.Dispatch(new Msg(9, []));
            },
            ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
        })), delay(() => singleton(defaultOf())))), delay(() => append((selectedDeleteReminder != null) ? singleton(createElement(DeleteReminder_OpenDialog, {
            IsOpen: selectedDeleteReminder != null,
            OnClose: () => {
                setSelectedDeleteReminder(undefined);
            },
            Reminder: unwrap(selectedDeleteReminder),
            SuccessCallback: () => {
                props.Dispatch(new Msg(9, []));
            },
            ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
        })) : empty(), delay(() => {
            let elems_2;
            const table = (rows) => {
                let TableRows, Options;
                return createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["startDate", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("state", "", t("general.done"), (reminderDate) => {
                        let elems;
                        return reminderDate.IsConfirmed ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "green",
                        })])) : ((compare(reminderDate.Date, now) > 0) ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "orange",
                        })])) : (new CellContent(1, [createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems = [Calendar_Warning()], ["children", reactApi.Children.toArray(Array.from(elems))])])))])));
                    }, "none", "text"), new ColumnDefinition$1("date", "", t("general.date"), (dto) => (new CellContent(5, [dto.Date])), "dateRange", "date"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Title])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_2) => (new CellContent(0, [dto_2.Description])), "none", "text")],
                    Controls: toList(delay(() => {
                        let matchValue_1;
                        return append(isFeatureEnabled(new Feature(8, [])) ? ((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "table-reminder")) : ((matchValue_1 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "table-reminder")) : ((matchValue_1 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "table-reminder")))))) : empty(), delay(() => {
                            let matchValue_2;
                            return append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    const planning = selectedRow;
                                    const matchValue_3 = props.UserData.Role;
                                    switch (matchValue_3) {
                                        case "user": {
                                            break;
                                        }
                                        default:
                                            setSelectedReminder(planning);
                                    }
                                }
                            }, true, false, "table-edit")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    const planning = selectedRow;
                                    const matchValue_3 = props.UserData.Role;
                                    switch (matchValue_3) {
                                        case "user": {
                                            break;
                                        }
                                        default:
                                            setSelectedReminder(planning);
                                    }
                                }
                            }, true, false, "table-edit")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    const planning = selectedRow;
                                    const matchValue_3 = props.UserData.Role;
                                    switch (matchValue_3) {
                                        case "user": {
                                            break;
                                        }
                                        default:
                                            setSelectedReminder(planning);
                                    }
                                }
                            }, true, false, "table-edit"))))), delay(() => {
                                let matchValue_4;
                                return append((matchValue_4 = props.UserData.Role, (matchValue_4 === "system") ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                    const selectedRow_1 = tryHead(rows_2);
                                    if (selectedRow_1 != null) {
                                        setSelectedReminderToConfirm(selectedRow_1);
                                    }
                                }, true, false, "table-mark-as-done")) : ((matchValue_4 === "administrator") ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                    const selectedRow_1 = tryHead(rows_2);
                                    if (selectedRow_1 != null) {
                                        setSelectedReminderToConfirm(selectedRow_1);
                                    }
                                }, true, false, "table-mark-as-done")) : ((matchValue_4 === "user") ? (empty()) : singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                                    const selectedRow_1 = tryHead(rows_2);
                                    if (selectedRow_1 != null) {
                                        setSelectedReminderToConfirm(selectedRow_1);
                                    }
                                }, true, false, "table-mark-as-done"))))), delay(() => {
                                    const matchValue_5 = props.UserData.Role;
                                    switch (matchValue_5) {
                                        case "user": {
                                            return empty();
                                        }
                                        default:
                                            return singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_3) => {
                                                const selectedRow_3 = tryHead(rows_3);
                                                if (selectedRow_3 == null) {
                                                }
                                                else {
                                                    const planning_1 = selectedRow_3;
                                                    const matchValue_6 = props.UserData.Role;
                                                    switch (matchValue_6) {
                                                        case "user": {
                                                            break;
                                                        }
                                                        default:
                                                            setSelectedDeleteReminder(planning_1);
                                                    }
                                                }
                                            }, true, false, "table-delete"));
                                    }
                                }));
                            }));
                        }));
                    })),
                    Options: Options,
                    TableRows: TableRows,
                })));
            };
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_2 = toList(delay(() => append(singleton(table((length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                let elems_1;
                return (length(reminders) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems_1 = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("general.show_more_data"),
                    OnClick: () => {
                        setIsModalOpen(true);
                    },
                    TestId: "tool-with-quantity-appointment-showmoredata-button",
                    Variant: "blueButton",
                }), createElement(ModalSheet, {
                    CloseButtonTestId: "",
                    Content: table(reminders),
                    Controls: empty_1(),
                    IsModalOpen: patternInput_1[0],
                    OnClose: () => {
                        setIsModalOpen(false);
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }))));
    }))))));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

function ReservationSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReservation = patternInput_3[1];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteReservation = patternInput_4[1];
    const selectedDeleteReservation = patternInput_4[0];
    const reservations = sortByDescending((r) => r.StartDate, props.ToolWithQuantity.Reservations, {
        Compare: compare,
    });
    const now = date_1(utcNow());
    const xs_2 = toList(delay(() => append(singleton(createElement(NewReservation_AddToolWithQuantityReservationDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
        UserData: props.UserData,
    })), delay(() => {
        let matchValue;
        return append((matchValue = patternInput_3[0], (matchValue == null) ? (empty()) : singleton(createElement(EditToolWithQuantityReservationDialog, {
            IsOpen: true,
            OnClose: () => {
                setSelectedReservation(undefined);
            },
            ReservationId: matchValue.Id,
            SuccessCallback: () => {
                props.Dispatch(new Msg(9, []));
            },
            ToolId: new ToolId_4(parse(props.ToolWithQuantity.Id)),
            UserData: props.UserData,
        }))), delay(() => append((selectedDeleteReservation != null) ? singleton(createElement(DeleteToolWithQuantityReservationDialog, {
            IsOpen: selectedDeleteReservation != null,
            OnClose: () => {
                setSelectedDeleteReservation(undefined);
            },
            Reservation: unwrap(selectedDeleteReservation),
            SuccessCallback: () => {
                props.Dispatch(new Msg(9, []));
            },
            ToolWithQuantity: props.ToolWithQuantity,
            UserData: props.UserData,
        })) : empty(), delay(() => {
            let elems_1;
            const table = (rows) => {
                let TableRows, Options;
                return createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["startDate", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto) => (new CellContent(5, [dto.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_1) => (new CellContent(5, [dto_1.EndDate])), "dateRange", "date"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_2) => (new CellContent(0, [dto_2.ReceiverInformation.ReceiverName])), "none", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_3) => (new CellContent(0, [int32ToString(dto_3.Quantity)])), "text", "text"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_4) => (new CellContent(0, [dto_4.StorageName])), "select", "text"), new ColumnDefinition$1("reservationName", "", t("general.name"), (dto_5) => (new CellContent(0, [dto_5.Name])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_6) => (new CellContent(0, [dto_6.Description])), "none", "text")],
                    Controls: toList(delay(() => append(isFeatureEnabled(new Feature(9, [])) ? singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                        setIsReservationDialogOpen(true);
                    }, false, false, "table-reservation")) : empty(), delay(() => {
                        let matchValue_1;
                        return append((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit")) : ((matchValue_1 === "toolManager") ? singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit")) : ((matchValue_1 === "user") ? (empty()) : singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                            setSelectedReservation(planning);
                                        }
                                        break;
                                    }
                                    default:
                                        setSelectedReservation(planning);
                                }
                            }
                        }, true, false, "table-edit"))))), delay(() => {
                            const matchValue_3 = props.UserData.Role;
                            switch (matchValue_3) {
                                case "user": {
                                    return empty();
                                }
                                default:
                                    return singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_2) => {
                                        const selectedRow_1 = tryHead(rows_2);
                                        if (selectedRow_1 == null) {
                                        }
                                        else {
                                            const planning_1 = selectedRow_1;
                                            const matchValue_4 = props.UserData.Role;
                                            switch (matchValue_4) {
                                                case "user": {
                                                    if (planning_1.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                                        setSelectedDeleteReservation(planning_1);
                                                    }
                                                    break;
                                                }
                                                default:
                                                    setSelectedDeleteReservation(planning_1);
                                            }
                                        }
                                    }, true, false, "table-delete"));
                            }
                        }));
                    })))),
                    Options: Options,
                    TableRows: TableRows,
                })));
            };
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(reservations) > 5) ? take(5, reservations) : reservations)), delay(() => {
                let elems;
                return (length(reservations) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("general.show_more_data"),
                    OnClick: () => {
                        setIsModalOpen(true);
                    },
                    TestId: "tool-with-quantity-reservations-showmoredata-button",
                    Variant: "blueButton",
                }), createElement(ModalSheet, {
                    CloseButtonTestId: "",
                    Content: table(reservations),
                    Controls: empty_1(),
                    IsModalOpen: patternInput_1[0],
                    OnClose: () => {
                        setIsModalOpen(false);
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
            })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
        }))));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function FormModal(props) {
    let elems;
    return createElement("div", createObj(singleton_1((elems = [createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: defaultOf(),
        Controls: empty_1(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export class CurrentUserAssignment extends Record {
    constructor(Id, Name, Quantity) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Quantity = Quantity;
    }
}

export function CurrentUserAssignment_$reflection() {
    return record_type("Tools.ToolWithQuantity.Details.View.CurrentUserAssignment", [], CurrentUserAssignment, () => [["Id", string_type], ["Name", string_type], ["Quantity", string_type]]);
}

export const scrollOptions = new (class {
    get behavior() {
        return "smooth";
    }
    set behavior(_arg) {
    }
    get block() {
        return "center";
    }
    set block(_arg_1) {
    }
    get inline() {
        return "center";
    }
    set inline(_arg_2) {
    }
}
)();

function DocumentSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(DocumentTable, {
        Documents: (length(props.Documents) > 5) ? take(5, props.Documents) : props.Documents,
        OnDownloadFile: (tupledArg) => {
            props.Dispatch(new Msg(24, [tupledArg[0], tupledArg[1]]));
        },
        UploadSuccessCallback: () => {
            props.Dispatch(new Msg(22, []));
        },
        UploadType: new UploadType_2(0, [props.ToolId]),
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.Documents) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(DocumentTable, {
                Documents: props.Documents,
                OnDownloadFile: (tupledArg_1) => {
                    props.Dispatch(new Msg(24, [tupledArg_1[0], tupledArg_1[1]]));
                },
                UploadSuccessCallback: () => {
                    props.Dispatch(new Msg(22, []));
                },
                UploadType: new UploadType_2(2, [props.ToolId]),
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ToolsInCirculationSection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsToolModalOpen = patternInput_1[1];
    const rows = map((d) => ({
        Name: d.ReceiverName,
        Quantity: d.Quantity,
    }), props.Tools);
    const table = (rows_1) => {
        let TableRows;
        return createElement(Table, (TableRows = toArray(rows_1), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.Name])), "none", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => {
                let copyOfStruct;
                return new CellContent(0, [(copyOfStruct = row_1.Quantity, int32ToString(copyOfStruct))]);
            }, "none", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "multiRow",
            },
            TableRows: TableRows,
        }));
    };
    return createElement("div", createObj(ofArray([["id", "tool-with-quantity-tool-section"], ["className", join(" ", ["flex", "flex-col", "bg-white"])], (elems_1 = toList(delay(() => append(singleton(table((length(rows) > 5) ? take(5, rows) : rows)), delay(() => {
        let elems;
        return (length(rows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsToolModalOpen(true);
            },
            TestId: "tool-toolwithquantity-toolsincirculation-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(rows),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsToolModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function StockSection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsToolModalOpen = patternInput_1[1];
    let responsibleStorageIds;
    const matchValue = props.UserData.Role;
    switch (matchValue) {
        case "toolManager": {
            responsibleStorageIds = map((storage_1) => storage_1.Id, filter((s) => contains(props.UserData.UserId, s.AssignedUserIds, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), props.Storages));
            break;
        }
        case "user": {
            responsibleStorageIds = empty_1();
            break;
        }
        default:
            responsibleStorageIds = map((storage) => storage.Id, props.Storages);
    }
    const containsStorageId = (storageId) => contains(storageId, responsibleStorageIds, {
        Equals: equals,
        GetHashCode: safeHash,
    });
    const patternInput_2 = reactApi.useState(undefined);
    const setIsDeleteStockPerStorageDialogOpen = patternInput_2[1];
    const isDeleteStockPerStorageDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setIsMinimumStockQuantityDialogOpen = patternInput_3[1];
    const isMinimumStockQuantityDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setIsStockChangeDialogOpen = patternInput_4[1];
    const patternInput_5 = reactApi.useState(undefined);
    const setIsChangeStorageSpaceDialogOpen = patternInput_5[1];
    const isChangeStorageSpaceDialogOpen = patternInput_5[0];
    const patternInput_6 = reactApi.useState(undefined);
    const setIsChangeStorageDialogOpen = patternInput_6[1];
    const isChangeStorageDialogOpen = patternInput_6[0];
    const rows = map((stockPerStorage) => ({
        MinimumQuantity: unwrap(stockPerStorage.MinimumStockQuantity),
        Name: stockPerStorage.StorageName,
        QRCode: isEmpty(stockPerStorage.QRCodeIds) ? "" : reduce((e1, e2) => (`${e1}, ${e2}`), stockPerStorage.QRCodeIds),
        Stock: stockPerStorage.Stock,
        StorageId: new StorageId_2(parse(stockPerStorage.StorageId)),
        StorageSpace: stockPerStorage.StorageSpace,
    }), props.StockPerStorages);
    const table = (rows_1) => {
        let TableRows, Options, matchValue_2;
        return createElement(Table, (TableRows = toArray(rows_1), (Options = {
            BorderStyle: "full",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "singleRow",
        }, {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.storage"), (row) => (new CellContent(0, [row.Name])), "none", "text"), new ColumnDefinition$1("storage", "", t("tool.storage_space"), (row_1) => (new CellContent(0, [row_1.StorageSpace])), "none", "text"), new ColumnDefinition$1("stock", "", t("tool.storage_stock"), (row_2) => {
                let copyOfStruct;
                return new CellContent(0, [(copyOfStruct = row_2.Stock, int32ToString(copyOfStruct))]);
            }, "none", "text"), new ColumnDefinition$1("minimum-quantity", "", t("general.minimum_quantity"), (row_3) => {
                const matchValue_1 = row_3.MinimumQuantity;
                return (matchValue_1 != null) ? (new CellContent(0, [int32ToString(matchValue_1)])) : (new CellContent(0, [""]));
            }, "none", "text"), new ColumnDefinition$1("qrCode", "", t("general.qr_code"), (row_4) => (new CellContent(0, [row_4.QRCode])), "none", "text")],
            Controls: (matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                if (isEmpty(selectedSps)) {
                    setIsStockChangeDialogOpen([true, undefined]);
                }
                else {
                    const matchValue_3 = tryHead(map((selectedTool) => selectedTool.StorageId, selectedSps));
                    if (matchValue_3 == null) {
                    }
                    else {
                        const selectedStorageId_1 = matchValue_3;
                        if (containsStorageId(selectedStorageId_1)) {
                            setIsStockChangeDialogOpen([true, selectedStorageId_1]);
                        }
                    }
                }
            }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                if (isEmpty(selectedSps_1)) {
                }
                else {
                    const matchValue_4 = tryHead(map((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                    if (matchValue_4 == null) {
                    }
                    else {
                        const selectedStorageId_3 = matchValue_4;
                        if (containsStorageId(selectedStorageId_3[1])) {
                            setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                if (isEmpty(selectedSps_2)) {
                }
                else {
                    setIsChangeStorageDialogOpen(tryHead(map((selectedSps_3) => StorageId__get_unwrap(selectedSps_3.StorageId), selectedSps_2)));
                }
            }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_4) => {
                if (isEmpty(selectedSps_4)) {
                }
                else {
                    const matchValue_5 = tryHead(map((selectedTool_2) => selectedTool_2.StorageId, selectedSps_4));
                    if (matchValue_5 == null) {
                    }
                    else {
                        const selectedStorageId_6 = matchValue_5;
                        if (containsStorageId(selectedStorageId_6)) {
                            setIsMinimumStockQuantityDialogOpen(selectedStorageId_6);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_5) => {
                if (isEmpty(selectedSps_5)) {
                }
                else {
                    const matchValue_6 = tryHead(map((selectedTool_3) => selectedTool_3.StorageId, selectedSps_5));
                    if (matchValue_6 == null) {
                    }
                    else {
                        const selectedStorageId_8 = matchValue_6;
                        if (containsStorageId(selectedStorageId_8)) {
                            setIsDeleteStockPerStorageDialogOpen(selectedStorageId_8);
                        }
                    }
                }
            }, true, false, "")]) : ((matchValue_2 === "toolManager") ? ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                if (isEmpty(selectedSps)) {
                    setIsStockChangeDialogOpen([true, undefined]);
                }
                else {
                    const matchValue_3 = tryHead(map((selectedTool) => selectedTool.StorageId, selectedSps));
                    if (matchValue_3 == null) {
                    }
                    else {
                        const selectedStorageId_1 = matchValue_3;
                        if (containsStorageId(selectedStorageId_1)) {
                            setIsStockChangeDialogOpen([true, selectedStorageId_1]);
                        }
                    }
                }
            }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                if (isEmpty(selectedSps_1)) {
                }
                else {
                    const matchValue_4 = tryHead(map((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                    if (matchValue_4 == null) {
                    }
                    else {
                        const selectedStorageId_3 = matchValue_4;
                        if (containsStorageId(selectedStorageId_3[1])) {
                            setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                if (isEmpty(selectedSps_2)) {
                }
                else {
                    setIsChangeStorageDialogOpen(tryHead(map((selectedSps_3) => StorageId__get_unwrap(selectedSps_3.StorageId), selectedSps_2)));
                }
            }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_4) => {
                if (isEmpty(selectedSps_4)) {
                }
                else {
                    const matchValue_5 = tryHead(map((selectedTool_2) => selectedTool_2.StorageId, selectedSps_4));
                    if (matchValue_5 == null) {
                    }
                    else {
                        const selectedStorageId_6 = matchValue_5;
                        if (containsStorageId(selectedStorageId_6)) {
                            setIsMinimumStockQuantityDialogOpen(selectedStorageId_6);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_5) => {
                if (isEmpty(selectedSps_5)) {
                }
                else {
                    const matchValue_6 = tryHead(map((selectedTool_3) => selectedTool_3.StorageId, selectedSps_5));
                    if (matchValue_6 == null) {
                    }
                    else {
                        const selectedStorageId_8 = matchValue_6;
                        if (containsStorageId(selectedStorageId_8)) {
                            setIsDeleteStockPerStorageDialogOpen(selectedStorageId_8);
                        }
                    }
                }
            }, true, false, "")]) : ((matchValue_2 === "user") ? empty_1() : ofArray([new TableControl$1(t("tool.update_stock"), Warehouse_1(), (selectedSps) => {
                if (isEmpty(selectedSps)) {
                    setIsStockChangeDialogOpen([true, undefined]);
                }
                else {
                    const matchValue_3 = tryHead(map((selectedTool) => selectedTool.StorageId, selectedSps));
                    if (matchValue_3 == null) {
                    }
                    else {
                        const selectedStorageId_1 = matchValue_3;
                        if (containsStorageId(selectedStorageId_1)) {
                            setIsStockChangeDialogOpen([true, selectedStorageId_1]);
                        }
                    }
                }
            }, false, false, ""), new TableControl$1(t("tool.change_storage_space"), Location_Building_Search(), (selectedSps_1) => {
                if (isEmpty(selectedSps_1)) {
                }
                else {
                    const matchValue_4 = tryHead(map((selectedTool_1) => [selectedTool_1.StorageSpace, selectedTool_1.StorageId], selectedSps_1));
                    if (matchValue_4 == null) {
                    }
                    else {
                        const selectedStorageId_3 = matchValue_4;
                        if (containsStorageId(selectedStorageId_3[1])) {
                            setIsChangeStorageSpaceDialogOpen(selectedStorageId_3);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("tool.switch_storage"), Transfer_Cart(), (selectedSps_2) => {
                if (isEmpty(selectedSps_2)) {
                }
                else {
                    setIsChangeStorageDialogOpen(tryHead(map((selectedSps_3) => StorageId__get_unwrap(selectedSps_3.StorageId), selectedSps_2)));
                }
            }, false, false, ""), new TableControl$1(t("general.minimum_quantity_update"), Notification_Alarm_1(), (selectedSps_4) => {
                if (isEmpty(selectedSps_4)) {
                }
                else {
                    const matchValue_5 = tryHead(map((selectedTool_2) => selectedTool_2.StorageId, selectedSps_4));
                    if (matchValue_5 == null) {
                    }
                    else {
                        const selectedStorageId_6 = matchValue_5;
                        if (containsStorageId(selectedStorageId_6)) {
                            setIsMinimumStockQuantityDialogOpen(selectedStorageId_6);
                        }
                    }
                }
            }, true, false, ""), new TableControl$1(t("general.delete"), Recycle_Bin_1(), (selectedSps_5) => {
                if (isEmpty(selectedSps_5)) {
                }
                else {
                    const matchValue_6 = tryHead(map((selectedTool_3) => selectedTool_3.StorageId, selectedSps_5));
                    if (matchValue_6 == null) {
                    }
                    else {
                        const selectedStorageId_8 = matchValue_6;
                        if (containsStorageId(selectedStorageId_8)) {
                            setIsDeleteStockPerStorageDialogOpen(selectedStorageId_8);
                        }
                    }
                }
            }, true, false, "")])))),
            Options: Options,
            TableRows: TableRows,
        })));
    };
    return createElement("div", createObj(ofArray([["id", "tool-with-quantity-stock-section"], ["className", join(" ", ["flex", "flex-col", "bg-white"])], (elems_1 = toList(delay(() => {
        let matchValue_7;
        return append((matchValue_7 = isDeleteStockPerStorageDialogOpen, (matchValue_7 == null) ? (empty()) : singleton(createElement(ToolWithQuantityStockPerStorageDeleteDialog, {
            IsOpen: isDeleteStockPerStorageDialogOpen != null,
            OnClose: () => {
                setIsDeleteStockPerStorageDialogOpen(undefined);
            },
            StorageId: matchValue_7,
            SuccessCallback: () => {
                props.Dispatch(new Msg(9, []));
            },
            ToolId: new ToolId_4(parse(props.Tool.Id)),
        }))), delay(() => {
            let matchValue_8;
            return append((matchValue_8 = isMinimumStockQuantityDialogOpen, (matchValue_8 == null) ? (empty()) : singleton(createElement(MinimumStockQuantityDialog, {
                IsOpen: isMinimumStockQuantityDialogOpen != null,
                OnClose: () => {
                    setIsMinimumStockQuantityDialogOpen(undefined);
                },
                StorageId: matchValue_8,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(9, []));
                },
                ToolId: new ToolId_4(parse(props.Tool.Id)),
            }))), delay(() => {
                let matchValue_9, selectedStorage_2, ToolId_2;
                return append((matchValue_9 = patternInput_4[0], (matchValue_9 == null) ? (empty()) : ((selectedStorage_2 = matchValue_9, singleton(createElement(ChangeStockDialog, (ToolId_2 = (new ToolId_4(parse(props.Tool.Id))), {
                    IsOpen: selectedStorage_2[0],
                    OnClose: () => {
                        setIsStockChangeDialogOpen(undefined);
                    },
                    StorageId: unwrap(selectedStorage_2[1]),
                    Storages: filter((storage_2) => containsStorageId(storage_2.Id), props.Storages),
                    SuccessCallback: () => {
                        props.Dispatch(new Msg(9, []));
                    },
                    ToolId: ToolId_2,
                })))))), delay(() => {
                    let matchValue_10;
                    return append((matchValue_10 = isChangeStorageSpaceDialogOpen, (matchValue_10 == null) ? (empty()) : singleton(createElement(ChangeStorageSpaceDialog, {
                        IsEditableForUser: true,
                        IsOpen: isChangeStorageSpaceDialogOpen != null,
                        OnClose: () => {
                            setIsChangeStorageSpaceDialogOpen(undefined);
                        },
                        StorageId: matchValue_10[1],
                        StorageSpace: matchValue_10[0],
                        SuccessCallback: () => {
                            props.Dispatch(new Msg(9, []));
                        },
                        ToolId: new ToolId_4(parse(props.Tool.Id)),
                    }))), delay(() => append(singleton(createElement(ChangeStorageDialog, {
                        IsOpen: isChangeStorageDialogOpen != null,
                        OnClose: () => {
                            setIsChangeStorageDialogOpen(undefined);
                        },
                        SelectedStorageId: unwrap(isChangeStorageDialogOpen),
                        Storages: filter((storage_3) => containsStorageId(storage_3.Id), props.Storages),
                        SuccessCallback: () => {
                            props.Dispatch(new Msg(9, []));
                        },
                        Tool: props.Tool,
                        UserData: props.UserData,
                    })), delay(() => append(singleton(table((length(rows) > 5) ? take(5, rows) : rows)), delay(() => {
                        let elems;
                        return (length(rows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("general.show_more_data"),
                            OnClick: () => {
                                setIsToolModalOpen(true);
                            },
                            TestId: "tool-toolwithquantity-tooldetail-showmoredata-button",
                            Variant: "blueButton",
                        }), createElement(ModalSheet, {
                            CloseButtonTestId: "",
                            Content: table(rows),
                            Controls: empty_1(),
                            IsModalOpen: patternInput_1[0],
                            OnClose: () => {
                                setIsToolModalOpen(false);
                            },
                        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
                    }))))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function HistorySection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsHistoryModalOpen = patternInput_1[1];
    const table = (rows) => {
        let TableRows;
        return createElement(Table, (TableRows = toArray(rows), {
            ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (dto) => (new CellContent(6, [dto.Timestamp])), "none", "dateTime"), new ColumnDefinition$1("eventType", "", t("general.event"), (dto_1) => (new CellContent(0, [t(Helper_historyTypeToTranslationString(dto_1.EventType))])), "select", "text"), new ColumnDefinition$1("comment", "", t("general.comment"), (dto_2) => {
                const matchValue = dto_2.Event;
                return (matchValue == null) ? (new CellContent(0, [t(dto_2.Comment)])) : (new CellContent(0, [Helper_displayHistoryComment(t(matchValue), dto_2.Comment)]));
            }, "none", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (dto_3) => (new CellContent(0, [int32ToString(dto_3.Quantity)])), "none", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (dto_4) => {
                let matchValue_1;
                return new CellContent(0, [(matchValue_1 = dto_4.ReceiverInformation, (matchValue_1 == null) ? "" : matchValue_1.Name)]);
            }, "text", "text"), new ColumnDefinition$1("sender", "", t("general.sender"), (dto_5) => (new CellContent(0, [dto_5.SenderInformation.Name])), "text", "text"), new ColumnDefinition$1("signatureUrl", "", t("general.signature"), (dto_6) => {
                let matchValue_2;
                return new CellContent(4, [(matchValue_2 = dto_6.ReceiverInformation, (matchValue_2 != null) ? defaultArg(map_1((x) => x, matchValue_2.SignatureUrl), "") : "")]);
            }, "none", "image"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_7) => (new CellContent(0, [defaultArg(map_1((storage) => storage.Name, dto_7.StorageInformation), "")])), "text", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "full",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["timestamp", "desc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }));
    };
    return createElement("div", createObj(ofArray([["id", "tool-with-quantity-history-section"], ["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(props.HistoryRows) > 5) ? take(5, props.HistoryRows) : props.HistoryRows)), delay(() => {
        let elems;
        return (length(props.HistoryRows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsHistoryModalOpen(true);
            },
            TestId: "tool-toolwithquantity-historysection-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(props.HistoryRows),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsHistoryModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function MasterDataSection(props) {
    let elems_4, elems_3, elems_1, Title, elems, elems_2, Title_2, p;
    const t = useTranslation()[0];
    const purchaseDate = (d) => {
        if (d == null) {
            return "";
        }
        else {
            return toString(toLocalTime(d), "dd.MM.yyyy");
        }
    };
    return createElement("div", createObj(ofArray([["id", "tool-with-quantity-masterdata-section"], ["className", join(" ", ["bg-white", "pl-10", "pt-5", "pb-5", "rounded"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-20", "h-30"])], (elems_3 = [createElement("div", createObj(singleton_1((elems_1 = [createElement(PropertyList, (Title = t("general.master_data"), {
        Properties: toList(delay(() => append(singleton({
            Label: t("tool.manufacturer"),
            TestId: "master-data-manufacturer",
            Value: props.Tool.MasterData.Manufacturer,
        }), delay(() => append(singleton({
            Label: t("tool.model"),
            TestId: "master-data-model",
            Value: props.Tool.MasterData.Model,
        }), delay(() => append(singleton({
            Label: t("tool.displayname"),
            TestId: "master-data-display-name",
            Value: props.Tool.MasterData.DisplayName,
        }), delay(() => append(singleton({
            Label: t("tool.inventory_number"),
            TestId: "master-data-inventory-number",
            Value: props.Tool.MasterData.InventoryNumber,
        }), delay(() => append(singleton({
            Label: t("general.comment"),
            TestId: "master-data-comment",
            Value: props.Tool.MasterData.Comment,
        }), delay(() => {
            if (props.Customer.tag === 1) {
                const matchValue_1 = props.Tool.CustomerSpecific;
                switch (matchValue_1.tag) {
                    case 1:
                        return singleton({
                            Label: t("tool.prinzing.check_number"),
                            TestId: "master-data-prinzing-check-number",
                            Value: matchValue_1.fields[0].CheckNumber,
                        });
                    default: {
                        return empty();
                    }
                }
            }
            else {
                return empty();
            }
        })))))))))))),
        Title: unwrap(Title),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems = [createElement(CustomPropertiesPropertyList, {
        CustomProperties: props.Tool.CustomProperties,
        Title: t("tool.custom_properties"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))), createElement("div", createObj(singleton_1((elems_2 = [createElement(PropertyList, (Title_2 = t("tool.commercial_data"), {
        Properties: ofArray([{
            Label: t("general.supplier"),
            TestId: "commercial-data-supplier",
            Value: props.Tool.CommercialData.Supplier,
        }, {
            Label: t("general.supplier_article_number"),
            TestId: "commercial-data-supplier-article-number",
            Value: props.Tool.CommercialData.SupplierArticleNumber,
        }, {
            Label: t("general.purchase_price"),
            TestId: "commercial-data-purchase-price",
            Value: (p = props.Tool.CommercialData.PurchasePrice, (p == null) ? "" : priceToString(p)),
        }, {
            Label: t("general.purchase_date"),
            TestId: "commercial-data-purchase-date",
            Value: purchaseDate(props.Tool.CommercialData.PurchaseDate),
        }, {
            Label: t("general.gurantee_date"),
            TestId: "commercial-data-guarantee-date",
            Value: purchaseDate(props.Tool.CommercialData.WarrantyDate),
        }, {
            Label: t("general.comment"),
            TestId: "commercial-data-comment",
            Value: props.Tool.CommercialData.Comment,
        }]),
        Title: unwrap(Title_2),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

function ToolDetailsAreaAnchorMenu(props) {
    let elems_1, elems;
    const patternInput = reactApi.useState("overview");
    const setButtonIsSelected = patternInput[1];
    const buttonIsSelected = patternInput[0];
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2.5", "sticky", "top-5"])], (elems = toList(delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("general.overview"),
        OnClick: () => {
            setButtonIsSelected("overview");
            document.getElementById("tool-with-quantity-overview-section").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-overview-navigation",
        Variant: (buttonIsSelected === "overview") ? "blueButton" : "default",
    })), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("general.master_data"),
        OnClick: () => {
            setButtonIsSelected("master_data");
            document.getElementById("tool-with-quantity-masterdata-section").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-master-navigation",
        Variant: (buttonIsSelected === "master_data") ? "blueButton" : "default",
    })), delay(() => append((isFeatureEnabled(new Feature(9, [])) ? true : isFeatureEnabled(new Feature(8, []))) ? singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("general.appointments"),
        OnClick: () => {
            setButtonIsSelected("appointments");
            document.getElementById("appointments-section-id").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-appointment-navigation",
        Variant: (buttonIsSelected === "appointments") ? "blueButton" : "default",
    })) : empty(), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("navbar.reservation"),
        OnClick: () => {
            setButtonIsSelected("reservations");
            document.getElementById("reservations-section-id").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-reservations-navigation",
        Variant: (buttonIsSelected === "reservations") ? "blueButton" : "default",
    })), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("tool.storage_stock"),
        OnClick: () => {
            setButtonIsSelected("stock");
            document.getElementById("tool-with-quantity-stock-section").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-stock-navigation",
        Variant: (buttonIsSelected === "stock") ? "blueButton" : "default",
    })), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("tool.in_circulation"),
        OnClick: () => {
            setButtonIsSelected("inventory_in_circulation");
            document.getElementById("tool-with-quantity-tool-section").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-circulation-navigation",
        Variant: (buttonIsSelected === "inventory_in_circulation") ? "blueButton" : "default",
    })), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("general.documents"),
        OnClick: () => {
            setButtonIsSelected("documents");
            document.getElementById("tool-with-quantity-document-section").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-documents-navigation",
        Variant: (buttonIsSelected === "documents") ? "blueButton" : "default",
    })), delay(() => append(singleton(createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: t("general.qr_code"),
        OnClick: () => {
            setButtonIsSelected("barcodes");
            document.getElementById("barcodes-section-id").scrollIntoView(scrollOptions);
        },
        TestId: "tool-with-quantity-barcodes-navigation",
        Variant: (buttonIsSelected === "barcodes") ? "blueButton" : "default",
    })), delay(() => {
        const matchValue = props.UserData.Role;
        switch (matchValue) {
            case "user": {
                return empty();
            }
            default:
                return singleton(createElement(SidebarTextButton, {
                    ComponentState: "enabled",
                    Label: t("general.history"),
                    OnClick: () => {
                        setButtonIsSelected("history");
                        document.getElementById("tool-with-quantity-history-section").scrollIntoView(scrollOptions);
                    },
                    TestId: "tool-with-quantity-history-navigation",
                    Variant: (buttonIsSelected === "history") ? "blueButton" : "default",
                }));
        }
    })))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function OverView(props) {
    let elems_11;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsAssignDialogOpen = patternInput_1[1];
    const isAssignDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsUnassignDialogOpen = patternInput_2[1];
    const isUnassignDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(false);
    const setIsLostDialogOpen = patternInput_3[1];
    const isLostDialogOpen = patternInput_3[0];
    const storage = sumBy((value) => value.Stock, props.Tool.StockPerStorage, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) | 0;
    const assignedUsers = sumBy((value_1) => value_1.Quantity, props.Tool.UserAssignments, {
        GetZero: () => 0,
        Add: (x_1, y_1) => (x_1 + y_1),
    }) | 0;
    return createElement("div", createObj(ofArray([["id", "tool-with-quantity-overview-section"], ["className", join(" ", ["flex", "flex-col", "grow"])], (elems_11 = toList(delay(() => append(isAssignDialogOpen ? singleton(createElement(AssignToolWithQuantityDialog, {
        IsOpen: isAssignDialogOpen,
        OnClose: () => {
            setIsAssignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        Tool: props.Tool,
        UserId: props.UserData.UserId,
    })) : empty(), delay(() => append(isUnassignDialogOpen ? singleton(createElement(UnassignToolWithQuantityDialog, {
        IsOpen: isUnassignDialogOpen,
        OnClose: () => {
            setIsUnassignDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        Tool: props.Tool,
        UserId: props.UserData.UserId,
    })) : empty(), delay(() => append(isLostDialogOpen ? singleton(createElement(LostToolWithQuantityDialog, {
        IsOpen: isLostDialogOpen,
        OnClose: () => {
            setIsLostDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(9, []));
        },
        Tool: props.Tool,
        UserId: props.UserData.UserId,
    })) : empty(), delay(() => {
        let elems_10, elems, elems_9, elems_1, matchValue_1, elems_8;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "pt-8", "pb-10", "rounded", "px-12"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-4", "mb-12"])], (elems = toList(delay(() => {
            let Label;
            const matchValue = props.UserData.Role;
            switch (matchValue) {
                case "user": {
                    return empty();
                }
                default:
                    return append(singleton(createElement(TextButton, (Label = t("general.assign"), {
                        ComponentState: "enabled",
                        Icon: Following(),
                        Label: Label,
                        OnClick: () => {
                            setIsAssignDialogOpen(true);
                        },
                        TestId: "tool-toolwithquantity-overview-assign-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return append(singleton(createElement(TextButton, (Label_1 = t("general.take_back"), {
                            ComponentState: "enabled",
                            Icon: Followers(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsUnassignDialogOpen(true);
                            },
                            TestId: "tool-toolwithquantity-overview-unassign-button",
                            Variant: "default",
                        }))), delay(() => {
                            let Label_2;
                            return singleton(createElement(TextButton, (Label_2 = t("general.report_lost"), {
                                ComponentState: "enabled",
                                Icon: Shipment_Search(),
                                Label: Label_2,
                                OnClick: () => {
                                    setIsLostDialogOpen(true);
                                },
                                TestId: "tool-toolwithquantity-overview-lost-button",
                                Variant: "default",
                            })));
                        }));
                    }));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(NotificationArea, {
            NotificationToolType: new NotificationToolType(1, []),
            Notifications: props.Notifications,
            OnUpdate: () => {
                props.Dispatch(new Msg(9, []));
            },
            UserData: props.UserData,
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mt-12"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "min-w-[35%]", "min-h-[50%]", "justify-start", "pl-10", "mr-5"])], (elems_1 = [createElement(ImageWidget, {
            CanEditImage: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? true : ((matchValue_1 === "toolManager") ? true : (!(matchValue_1 === "user")))),
            ImageUrl: props.Tool.ImageUrl,
            OnDeleteImage: () => {
                props.Dispatch(new Msg(11, []));
            },
            OnUploadImage: (file) => {
                props.Dispatch(new Msg(10, [file]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "ml-10"])], (elems_8 = toList(delay(() => {
            let elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["min-w-full", "text-sm", "font-semibold", "mb-2"])], ["data-test-id", "tool-with-quantity-total-stock-test-id"], (elems_2 = [t("general.total_stock")], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_4;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_4 = toList(delay(() => {
                    let elems_3;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-1"])], (elems_3 = [createElement(ColoredDot, {
                        Color: (length(props.Storages) !== 0) ? "green" : "red",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => singleton(createElement(TextValue, {
                        FontWeight: "normal",
                        TestId: "tool-with-quantity-storage-test-id",
                        Text: `${storage} ${t("unit.piece")}`,
                    }))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["min-w-full", "text-sm", "font-semibold", "mb-2"])], (elems_5 = [t("tool.in_circulation")], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                        let elems_6;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-2"])], (elems_6 = toList(delay(() => singleton(createElement(TextValue, {
                            FontWeight: "normal",
                            TestId: "tool-with-quantity-assigedn-user-test-id",
                            Text: `${assignedUsers} ${t("unit.piece")}`,
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                            let elems_7;
                            return isFeatureEnabled(new Feature(3, [])) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_7 = [createElement(QuickOverviewList, {
                                Elements: {
                                    CommercialData: props.Tool.CommercialData,
                                    Custom: toList(delay(() => {
                                        let Label_3;
                                        return append(singleton((Label_3 = t("tool.manufacturer"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-manufacturer-fav-field",
                                            Label: Label_3,
                                            Value: props.Tool.MasterData.Manufacturer,
                                        })), delay(() => {
                                            let Label_4;
                                            return append(singleton((Label_4 = t("tool.model"), {
                                                Group: t("general.master_data"),
                                                Key: "tool-model-fav-field",
                                                Label: Label_4,
                                                Value: props.Tool.MasterData.Model,
                                            })), delay(() => {
                                                let Label_5;
                                                return append(singleton((Label_5 = t("tool.displayname"), {
                                                    Group: t("general.master_data"),
                                                    Key: "tool-displayname-fav-field",
                                                    Label: Label_5,
                                                    Value: props.Tool.MasterData.DisplayName,
                                                })), delay(() => {
                                                    let Label_6;
                                                    return append(singleton((Label_6 = t("tool.inventory_number"), {
                                                        Group: t("general.master_data"),
                                                        Key: "tool-inventorynumber-fav-field",
                                                        Label: Label_6,
                                                        Value: props.Tool.MasterData.InventoryNumber,
                                                    })), delay(() => {
                                                        let Label_7;
                                                        if (props.UserData.Customer.tag === 1) {
                                                            const matchValue_3 = props.Tool.CustomerSpecific;
                                                            switch (matchValue_3.tag) {
                                                                case 1:
                                                                    return singleton((Label_7 = t("tool.prinzing.check_number"), {
                                                                        Group: t("general.master_data"),
                                                                        Key: "tool-prinzing-check-number-fav-field",
                                                                        Label: Label_7,
                                                                        Value: matchValue_3.fields[0].CheckNumber,
                                                                    }));
                                                                default: {
                                                                    return empty();
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            return empty();
                                                        }
                                                    }));
                                                }));
                                            }));
                                        }));
                                    })),
                                    CustomProperties: props.Tool.CustomProperties,
                                },
                                IsDialogOpen: props.QuickOverviewDialog.IsOpen,
                                IsLoading: props.QuickOverviewDialog.IsLoading,
                                OnSave: (updatedVisibleKeys) => {
                                    props.Dispatch(new Msg(0, [updatedVisibleKeys]));
                                },
                                SetDialogOpen: (_arg_11) => {
                                    props.Dispatch(new Msg(1, []));
                                },
                                UserData: props.UserData,
                                VisibleKeys: props.UserConfiguration.QuickOverviewConfiguration.ToolWithQuantityViewVisibleFieldKeys,
                            })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))) : empty();
                        }));
                    }));
                }));
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))));
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems_11))])])));
}

function BarcodeSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    let tableData;
    const list_7 = collect((x) => x, map((stockPerStorage) => choose((qrCodeId) => ({
        QrCode: qrCodeId,
        StorageId: new StorageId_2(parse(stockPerStorage.StorageId)),
        StorageName: stockPerStorage.StorageName,
        StorageSpace: stockPerStorage.StorageSpace,
    }), stockPerStorage.QRCodeIds), props.StockPerStorage));
    tableData = append_1(choose((stockPerStorage_2) => ({
        QrCode: "",
        StorageId: new StorageId_2(parse(stockPerStorage_2.StorageId)),
        StorageName: stockPerStorage_2.StorageName,
        StorageSpace: stockPerStorage_2.StorageSpace,
    }), filter((stockPerStorage_1) => isEmpty(stockPerStorage_1.QRCodeIds), props.StockPerStorage)), list_7);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(QrCodeTableWithStorage, {
        Data: (length(tableData) > 5) ? take(5, tableData) : tableData,
        OnAssignQrCode: (tupledArg) => {
            props.Dispatch(new Msg(14, [tupledArg[0], tupledArg[1]]));
        },
        OnDeleteQrCodes: (tupledArg_1) => {
            props.Dispatch(new Msg(16, [tupledArg_1[0], tupledArg_1[1]]));
        },
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(tableData) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(QrCodeTableWithStorage, {
                Data: tableData,
                OnAssignQrCode: (value_2) => {
                },
                OnDeleteQrCodes: (value_3) => {
                },
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ToolWithQuantityDetail(props) {
    let elems_12, elems_11, elems, Title, SubTitle, matchValue, matchValue_1, elems_10;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_12 = [createElement(OpenToolDeleteDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        SuccessCallback: () => {
            props.Dispatch(new Msg(12, []));
        },
        ToolId: new ToolId_4(parse(props.Tool.Id)),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]", "print:flex", "print:flex-col"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, (Title = ((props.Tool.MasterData.DisplayName !== "") ? props.Tool.MasterData.DisplayName : (`${props.Tool.MasterData.Manufacturer} ${props.Tool.MasterData.Model}`)), (SubTitle = ((props.Tool.MasterData.DisplayName !== "") ? (`${props.Tool.MasterData.Manufacturer} ${props.Tool.MasterData.Model}`) : undefined), {
        Badges: map((category) => createElement(Badge, {
            Color: category.ColorHex,
            Label: category.CategoryName,
            TestId: "1-badge-test-id",
        }), props.Tool.Categories),
        OnBack: () => {
            RouterModule_nav(singleton_1("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
        },
        OnDelete: unwrap((props.UserData.Role === "administrator") ? (() => {
            setIsDeleteDialogOpen(true);
        }) : undefined),
        OnDuplicate: unwrap((matchValue = props.UserData.Role, (matchValue === "system") ? (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        OnEdit: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["toolswithquantity", props.Tool.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        SubTitle: unwrap(SubTitle),
        Title: Title,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(ToolDetailsAreaAnchorMenu, {
        UserData: props.UserData,
    }), createElement("div", createObj(singleton_1((elems_10 = toList(delay(() => append(singleton(createElement(OverView, {
        Dispatch: props.Dispatch,
        Notifications: props.Notifications,
        QuickOverviewDialog: props.QuickOverviewDialog,
        Storages: props.Storages,
        Tool: props.Tool,
        UserConfiguration: props.UserConfiguration,
        UserData: props.UserData,
    })), delay(() => {
        let elems_2, elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["pt-10"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["font-[600]", "pb-4", "text-sm"])], (elems_1 = [t("general.master_data")], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(MasterDataSection, {
            Customer: props.UserData.Customer,
            Tool: props.Tool,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3, Title_1;
            return append((isFeatureEnabled(new Feature(9, [])) ? true : isFeatureEnabled(new Feature(8, []))) ? append(singleton(createElement("section", createObj(ofArray([["id", "appointments-section-id"], (elems_3 = [createElement(TableContainer, (Title_1 = t("general.appointments"), {
                Content: createElement(AppointmentsSection, {
                    Dispatch: props.Dispatch,
                    ReceiverNames: props.ReceiverNames,
                    ToolWithQuantity: props.Tool,
                    UserData: props.UserData,
                }),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_4, Title_2;
                return singleton(createElement("section", createObj(ofArray([["id", "reservations-section-id"], (elems_4 = [createElement(TableContainer, (Title_2 = t("navbar.reservation"), {
                    Content: createElement(ReservationSection, {
                        Dispatch: props.Dispatch,
                        ReceiverNames: props.ReceiverNames,
                        ToolWithQuantity: props.Tool,
                        UserData: props.UserData,
                    }),
                    Title: unwrap(Title_2),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))));
            })) : empty(), delay(() => {
                let elems_5, Title_3;
                return append(singleton(createElement("section", createObj(singleton_1((elems_5 = [createElement(TableContainer, (Title_3 = t("tool.storage_stock"), {
                    Content: createElement(StockSection, {
                        Dispatch: props.Dispatch,
                        StockPerStorages: props.Tool.StockPerStorage,
                        Storages: props.Storages,
                        Tool: props.Tool,
                        UserData: props.UserData,
                        UserId: props.UserData.UserId,
                    }),
                    Title: unwrap(Title_3),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_5))]))))), delay(() => {
                    let elems_6, Title_4;
                    return append(singleton(createElement("section", createObj(singleton_1((elems_6 = [createElement(TableContainer, (Title_4 = t("tool.in_circulation"), {
                        Content: createElement(ToolsInCirculationSection, {
                            Tools: props.Tool.UserAssignments,
                        }),
                        Title: unwrap(Title_4),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))]))))), delay(() => {
                        let elems_7, Title_5;
                        return append(singleton(createElement("section", createObj(ofArray([["id", "tool-with-quantity-document-section"], (elems_7 = [createElement(TableContainer, (Title_5 = t("general.documents"), {
                            Content: createElement(DocumentSection, {
                                Dispatch: props.Dispatch,
                                Documents: props.Documents,
                                IsVisibleForUser: true,
                                ToolId: new ToolId_4(parse(props.Tool.Id)),
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_5),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                            let elems_8, Title_6;
                            return append(singleton(createElement("section", createObj(ofArray([["id", "barcodes-section-id"], (elems_8 = [createElement(TableContainer, (Title_6 = t("general.qr_code"), {
                                Content: createElement(BarcodeSection, {
                                    Dispatch: props.Dispatch,
                                    StockPerStorage: props.Tool.StockPerStorage,
                                    Storages: props.Storages,
                                    UserData: props.UserData,
                                }),
                                Title: unwrap(Title_6),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                                let elems_9, Title_7;
                                const matchValue_2 = props.UserData.Role;
                                switch (matchValue_2) {
                                    case "user": {
                                        return empty();
                                    }
                                    default:
                                        return singleton(createElement("section", createObj(singleton_1((elems_9 = [createElement(TableContainer, (Title_7 = t("general.history"), {
                                            Content: createElement(HistorySection, {
                                                HistoryRows: props.History,
                                            }),
                                            Title: unwrap(Title_7),
                                        }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])))));
                                }
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_10))]))))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])));
}

export function ToolWithQuantityDetailView(props) {
    let patternInput;
    const init = initViewToolWithQuantity(props.ToolId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Tool;
    const matchValue_1 = state_1.History;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.UserConfiguration;
    const matchValue_4 = state_1.Documents;
    const matchValue_5 = state_1.Receivers;
    const matchValue_6 = state_1.Notifications;
    let matchResult, documents, history, notifications, receiverNames, storages, tool, userConfiguration;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        documents = matchValue_4.fields[0];
        history = matchValue_1.fields[0];
        notifications = matchValue_6.fields[0];
        receiverNames = matchValue_5.fields[0];
        storages = matchValue_2.fields[0];
        tool = matchValue.fields[0];
        userConfiguration = matchValue_3.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ToolWithQuantityDetail, {
                Dispatch: patternInput[1],
                Documents: documents,
                History: history,
                Notifications: notifications,
                QuickOverviewDialog: state_1.QuickOverviewDialog,
                ReceiverNames: receiverNames,
                State: state_1,
                Storages: storages,
                Tool: tool,
                UserConfiguration: userConfiguration,
                UserData: state_1.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

